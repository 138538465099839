/*variables*/
$borderBase: $border-thin solid $color-brand--tertiary;
$borderHover: $border-thin solid $color-brand--primary;
$borderChecked: $border-thin solid $color-brand--primary;
$borderDisabled: $border-thin solid color('gray', 'separator');
$checkboxBackground: color('basic', 'white');
$checkboxBackground-checked: $color-brand--primary;
$checkboxBackground-disabled: color('basic', 'white');
$checkmarkBorder: $border-thin solid color('basic', 'white');
$checkboxText-color: color('gray', 'darken-3');
$colorDisabled: color('gray', 'separator');

/* Customize the label (the container) */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 25px;
  font-size: 12px;
  line-height: 20px;
  color: $checkboxText-color;
  cursor: pointer;
  user-select: none;
  margin-bottom: 20px;
  transition: $transition-fast;

  &:hover {
    .checkmark {
      border: $borderHover;
    }
  }

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    transition: $transition-fast;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: $checkboxBackground;
    border: $borderBase;
    transition: $transition-fast;
    border-radius: $borderRadius-small;
  }

  /* On mouse-over, add a grey background color */
  &:hover {
    input {
      & ~ .checkmark {
        background-color: color('gray', 'separator');
      }
    }
  }
  &:hover input ~ .checkmark {
    background-color: color('gray', 'separator');
  }

  /* When the checkbox is checked, add a blue background */
  input:checked {
    & ~ .checkmark {
      background-color: $checkboxBackground-checked;
      border: $borderChecked;
    }
  }

  /*Style for disabled checkbox*/
  input:disabled {
    &~.checkmark {
      border: $borderDisabled;
      background: $checkboxBackground-disabled;
    }
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    opacity: 0;
    transition: $transition-fast;
  }

  /* Show the checkmark when checked */
  input:checked {
    & ~ .checkmark {
      &:after {
        transform: translate(0%, 0%) rotate( 45deg ) scale( 1 ) translateZ(0);
        opacity: 1;
      }
    }
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 5px;
    top: 0;
    width: 7px;
    height: 12px;
    border: $checkmarkBorder;
    border-width: 0 2px 2px 0;
    transform:translate(50%, 50%) rotate( -45deg ) scale( 0 ) translateZ(0);
  }
}

/* checbox style with other input elements */
.checkbox {
  &-wrapper {
    .validation-field-wrapper {
      margin-bottom: 0;
      height: 78px;
      @include row-center--start;
    }
    .checkbox-container {
      margin-bottom: 0;
    }
  }
}

