.change-password {
    &-input {
      position: relative;

      .validation-field-label {
        text-align: left;
      }
      .btn {
        span {
          display: block;
        }
      }

      &~.form-footer {
        .btn--primary {
          border-radius: $borderRadius-medium;
          width: auto;
          height: auto;
          text-transform: none;
          padding: 10px 20px;
          font-size: $fontSize-mili -1;

          span {
            display: block;
          }

        }
      }
    }
    &-error-message {
      position: absolute;
      bottom: 25px;
      left: 20px;
      color: color('basic', 'red');
      font-size: $fontSize-mili;
    }

}
