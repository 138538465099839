/* Buttons variables */
//primary button
$primary-background: $color-brand--primary;
$primary-color: color('basic', 'white');
$primary-border: 1px solid transparent;
$primary-shadow: $shadow-primary;

$primary-backgroundHover: #275ecf;
$primary-colorHover: color('basic', 'white');
$primary-borderHover: 1px solid transparent;
$primary-shadowHover: $shadow-hover;

//secondary button
$secondary-background: $color-brand--secondary;
$secondary-color: color('basic', 'white');
$secondary-border: 1px solid color('brand', 'secondary');
$secondary-shadow: $shadow-primary;

$secondary-backgroundHover: lighten($color-brand--secondary, 10%);
$secondary-colorHover: color('basic', 'white');
$secondary-borderHover: 1px solid color('brand', 'secondary');
$secondary-shadowHover: $shadow-primary;

//danger button
$danger-background: color('basic', 'white');
$danger-color: color('basic', 'red');
$danger-border: 1px solid color('basic', 'red');

$danger-backgroundHover: darken(color('basic', 'red'), 5);
$danger-colorHover: color('basic', 'white');
$danger-borderHover: 1px solid color('basic', 'red');

//warning button
$warning-background: color('basic', 'orange');
$warning-color: color('basic', 'white');
$warning-border: 1px solid color('basic', 'orange');

$warning-backgroundHover: darken(color('basic', 'orange'), 5);
$warning-colorHover: color('basic', 'white');
$warning-borderHover: 1px solid color('basic', 'orange');

//simple button
$simple-background: transparent;
$simple-color: color('basic', 'white');
$simple-border: 1px solid transparent;

$simple-backgroundHover: transparent;
$simple-colorHover: color('basic', 'white');
$simple-borderHover: 1px solid transparent;

//simple reverse button
$simpleReverse-background: transparent;
$simpleReverse-color: color('gray', 'darken-4');
$simpleReverse-border: 1px solid transparent;

$simpleReverse-backgroundHover: transparent;
$simpleReverse-colorHover: color('gray', 'darken-4');
$simpleReverse-borderHover: 1px solid transparent;

//simple reverse button
$circle-background: color('brand', 'primary');
$circle-color: color('basic', 'white');
$circle-border: 1px solid transparent;

$circle-backgroundHover: darken(color('brand', 'primary'), 2);
$circle-colorHover: color('basic', 'white');
$circle-borderHover: 1px solid transparent;


//small button
$fontSize-btnSmall: 11px;

.btn {
  width: auto;
  transition: $transition-fast;
  position: relative;
  padding: 10px 15px;
  font-size: $fontSize-mili;
  font-weight: 400;
  text-decoration: none;
  letter-spacing: 0;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  border-radius: $borderRadius-medium;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @include media-breakpoint-up(md) {
    padding: 15px 30px;
  }

  .material-icons {
    font-size: $fontSize-mega + 4;
    margin-right: 10px;
    @include media-breakpoint-up(sm) {
      font-size: $fontSize-mili;
    }
  }

  span {
    @include row-center--center;
  }

  .material-icons.bordered-round {
    border: 1px solid color('basic','white');
    border-radius: 50%;
  }

  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }

  // TODO use SCSS functions, stay DRY [ do not repeat yourself ] ;)
  &--primary {
    background: $primary-background;
    color: $primary-color;
    border: $primary-border;
    box-shadow: $primary-shadow;
    &:focus,
    &:hover {
      background: $primary-backgroundHover;
      color: $primary-colorHover;
      border: $primary-borderHover;
      box-shadow: $primary-shadowHover;
    }
  }
  &--secondary {
    background: $secondary-background;
    color: $secondary-color;
    border: $secondary-border;
    box-shadow: $secondary-shadow;
    &:focus,
    &:hover {
      background: $secondary-backgroundHover;
      color: $secondary-colorHover;
      border: $secondary-borderHover;
      box-shadow: $secondary-shadowHover;
    }
  }
  &--danger {
    background: $danger-background;
    color: $danger-color;
    border: $danger-border;
    &:focus,
    &:hover {
      background: $danger-backgroundHover;
      color: $danger-colorHover;
      border: $danger-borderHover;
      box-shadow: $shadow-basic;
    }
  }
  &--simple {
    background: $simple-background;
    color: $simple-color;
    border: $simple-border;
    padding: 5px;
    &:focus,
    &:hover {
      background: $simple-backgroundHover;
      color: $simple-colorHover;
      border: $simple-borderHover;
      box-shadow: $shadow-basic;
    }
  }
  &--simpleReverse {
    background: $simpleReverse-background;
    color: $simpleReverse-color;
    border: $simpleReverse-border;
    padding: 5px;
    &:focus,
    &:hover {
      background: $simpleReverse-backgroundHover;
      color: $simpleReverse-colorHover;
      border: $simpleReverse-borderHover;
    }
  }

  &--circle {
    background: $circle-background;
    color: $circle-color;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 5px;
    &:focus,
    &:hover {
      background: $circle-backgroundHover;
      color: $circle-colorHover;
      box-shadow: $shadow-basic;
    }
  }
  &--circleSimple {
    background: $simple-background;
    color: $simpleReverse-color;
    border: $simple-border;
    padding: 5px;
    font-size: 20px;
  }
  &--warning {
    background: $warning-background;
    color: $warning-color;
    border: $warning-border;

    &:hover {
      background: $warning-backgroundHover;
      color: $warning-colorHover;
      border: $warning-borderHover;
    }
  }
  &--small {
    font-size: $fontSize-btnSmall;
    padding: 10px 20px;
  }
  &--large {
    font-size: $fontSize-normal;
    padding: 18px 36px;
  }
  .pulse-effect {
    position: absolute;
    top: -3px;
    left: -1px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: block;
    box-shadow: 0 0 0 0 rgba($simpleReverse-color, .15);
    &:hover {
      animation: pulse 2s infinite;
    }
  }
  a {
    color: color('gray', 'darken-4');
    font-size: $fontSize-giga;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($simpleReverse-color, .15);
  }
  20% {
    box-shadow: 0 0 3px 10px rgba($simpleReverse-color, .15);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($simpleReverse-color, .15);
  }
}
