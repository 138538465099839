/* Border radius */
$borderRadius-small: 2px;
$borderRadius-medium: 4px;
$borderRadius-large: 6px;
$borderRadius-largest: 8px;

/* Border weight */
$border-thin: 1px;
$border-normal: 2px;
$border-thick: 3px;
