$search-Border: $border-thin solid $color-brand--tertiary;
.search-bar {
  position: relative;
  padding: 10px 0;
  border: $search-Border;
  margin-left: 10px;
  margin-bottom: 10px;
  @include media-breakpoint-up(xl) {
    margin: 0 10px 0 0;
  }

  &-input {
    height: 28px;
    font-size: 14px;
    padding: 0 25px 0 0;
    border: none;
    border-bottom: $search-Border;
    background: transparent;
    &:focus {
      outline: none;
    }
  }

  .btn{
    position: absolute;
    right: -5px;
    bottom: 0;
    padding: 0;
    .material-icons {
      font-size: $fontSize-mega;
    }

    &.close-icon {
      top: -10px;
      .material-icons {
        font-size: $fontSize-mili;
      }
    }
  }
}
