$border-background-active: $color-brand--primary;
$color-active: $color-brand--primary;
$option-background: color('gray', 'text');
$option-color: color('basic', 'black');
$option-border:$border-thin solid $color-brand--tertiary;
.ember-power-select {
  &-trigger {
    border: $border-thin solid transparent;
    border-bottom: $option-border;
    border-radius: 0;
    background: none;
    transition: $transition-fast;
    width: 200px;
    &-active {
      outline: none;
      border: $border-thin solid transparent;
      border-bottom: $border-thin solid $border-active;
    }
    &:focus {
      outline: none;
      border: $border-thin solid transparent;
      border-bottom: $border-thin solid $border-active;
    }
  }
  &-status-icon {
    border: $border-thin solid transparent;
    transition: $transition-medium;
    font-size: 0;

    &:after {
      content: '\e5c5';
      font-family: 'Material Icons';
      font-size: $fontSize-normal;
      position: absolute;
      top: -16px;
      left: -10px;
      transform: rotate(0);
    }
  }
  &-search-input {
    border: $border-thin solid $border-background-active;
    position: relative;
    padding-left: 25px;
    &:focus {
      outline: none;
    }
  }
  &-search {
    &:after {
      position: absolute;
      font-family: 'Material Icons';
      content: '\e8b6';
      top: 5px;
      left: 10px;
      color: $border-background-active;
    }
  }
  &-options {
    border-bottom: $border-thin solid $option-background;
    color: black;
  }
  &-option[aria-current="true"] {
    background-color: $option-background;
    color: $option-color;

  }
  &-dropdown {
    border: $border-thin solid $option-background;
    margin-top: 4px;
   &.ember-basic-dropdown-content--below {
     border-bottom: 1px solid transparent;
   }
  }
  &-trigger[aria-expanded="true"] {
    .ember-power-select-status-icon {
      transform: rotate(0);
      color: $color-active;
    }
  }
  &-trigger--active {
    border-bottom: $border-thin solid $option-background;
  }
}

