$uploadBorder: 1px dashed $color-brand--primary;
$uploadIcon: rgba(color('gray', 'separator'), .3);
.file-upload {
  position: absolute;
  bottom: 25%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .btn--primary {
    position: relative;
    z-index: 3;
    padding: 10px;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(md) {
      padding: 15px 30px;
    }
  }

  &-wrapper {
    width: 100%;
  }

  &-dropZone-wrapper {
    @include row-center--start;
    position: relative;
  }

  &-dropZone {
    width: 100%;
    height: 295px;
    border: $uploadBorder;
    font-size: $fontSize-giga;
    @include column-center--center;
    border-radius: $borderRadius-large;
    margin-bottom: 30px;
    .upload-image {
      font-size: 150px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 0;
      color: $uploadIcon;
      margin-top: 20px;
    }
    .media-text {
      position: relative;
      z-index: 1;
      font-size: $fontSize-mega;
      text-align: center;
      @include media-breakpoint-up(md) {
        font-size: $fontSize-tera;
      }
    }
  }
}
