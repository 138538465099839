/* main wrapper variables */
$main-background: color('gray', 'background');
$main-color: color('brand', 'secondary');
$btnColor: $color-brand--primary;


.main {
  &-wrapper {
    width: calc(100% - #{$sideBar-width--small});
    background: $main-background;
    color: $main-color;
    height: 100vh;
    position: static;
    float: left;
    margin-left: $sideBar-width--small;
    overflow: hidden;
    transition: $transition-medium;
    @include media-breakpoint-up(lg) {
      width: calc(100% - #{$sideBar-width});
      margin-left: $sideBar-width;
    }
  }
  &-wrapper--large {
    width: calc(100% - 60px);
    margin-left: 60px;
  }
  &-wrapper--large .main-wrapper {
    margin-left: 60px;
  }
  &-content {
    position: relative;
    height: 100vh;
    overflow-x: auto;
    padding: 80px 20px 20px 20px;
    @include media-breakpoint-up(md) {
      padding: 80px 40px 40px 40px;
    }
    &-header {
      align-items: center;
      margin-bottom: 20px;
    }

    .text-right {
      @include row-bottom--flexEnd;
      margin: 10px 0;
      @include media-breakpoint-up(md) {
        justify-content: flex-end;
        margin: 0;
      }
      .btn--primary {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        padding: 5px;
        text-transform: uppercase;
        @include media-breakpoint-up(md) {
          padding: 15px 30px;
          border-radius: $borderRadius-medium;
          width: auto;
          height: auto;
        }
        span {
          display: none;
          @include media-breakpoint-up(md) {
            display: block;
          }
        }
      }
      .material-icons {
        margin-right: 0;
        @include media-breakpoint-up(md) {
          margin-right: 10px;
        }
      }
    }

    &-tooltip {
      position: absolute !important;
      display: block;
      right: 0 !important;
      top: 0 !important;
      left: inherit !important;
      transform: translateX(-85px) translateY(100px) translateZ(0) !important;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
    &-notification {
      position: relative;
      width: 250px;
      padding: 20px;
      background: color('basic', 'white');
      border-radius: $borderRadius-medium;
      box-shadow: $shadow-box;
      text-decoration: none;
      color: $color-brand--secondary;
      z-index: $zIndex-5;
      margin-bottom: 10px;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: auto;
      margin-left: 10px;
    }
  }
}
