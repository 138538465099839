//variables
$icon-color: $color-brand--secondary;

.title-line {
  &-label {
    @include row-center--start;
    margin-left: 10px;
    padding: 5px 0;
    i {
      font-size: $fontSize-tera;
      color: $icon-color;
    }
    .title {
      margin-bottom: 0;
      font-size: $fontSize-mega;
      font-weight: 500;
      @include media-breakpoint-up(md) {
        font-size: $fontSize-giga;
      }
    }
  }
}
