//variables
$commentBackground: color('basic', 'white');
$comment-simpleBorder: $border-thin solid transparent;
$border-input: $border-thin solid color('gray','separator');
$sendColor: $color-brand--primary;
$textArea-border: $border-thin solid color('gray', 'separator');
$dateColor: color('gray', 'separator');
$actionIcons: $color-brand--primary;
$actionBorder: $color-brand--primary;
$confirmColor: color('basic', 'green');
$confirmColor-text: color('basic', 'white');
$noteColor: color('basic', 'red');
.comment {
  margin-bottom: 10px;
  padding: 0;
  width: 100%;
  @include row-center--start;
  flex-wrap: wrap;
  background-color: $commentBackground;
  border-bottom: $textArea-border;
  @include media-breakpoint-up(md) {
    padding: 25px;
  }

  &-wrapper {
    word-wrap: break-word;
  }

  &-header-wrapper {
    width: 100%;
    @include column-center--center;
    .material-icons {
      color: $actionIcons;
      font-size: $fontSize-mega;
      margin-bottom: 5px;
    }
    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  &-author {
    font-weight: 500;
    margin-left: 20px;
  }

  &-date {
    margin: 24px 0;
    font-size: $fontSize-mili;
    @include row-center--start;
    @include media-breakpoint-up(md) {
      width: 25%;
      font-size: $fontSize-normal;
    }

    .material-icons {
      font-size: $fontSize-mega;
      margin-right: 10px;
      color: $dateColor;
    }
  }

  .user-wrapper {
    @include row-center--start;
    @include media-breakpoint-up(md) {
      width: 250px;
    }
  }

  &-list {
    @include column-start--spaceBetween;
  }

  &-creator {
    margin-bottom: 10px;
    @include row-start--flexStart;
    position: relative;
    width: 50%;
    .btn {
      padding: 0;
      padding-left: 15px;
      margin-left: -30px;
      z-index: 12;

      .material-icons {
        font-size: $fontSize-mega;
        margin-left: -25px;
        margin-top: 10px;
        transition: $transition-fast;
        color: $sendColor;
      }
    }
  }

  &-input {
    margin-bottom: 10px;
    margin-right: 25px;
    padding: 10px 20px 10px 0;
    min-width: 150px;
    font-size: $fontSize-normal;
    width: 100%;
    border: none;
    border-bottom: $border-input;
    z-index: 10;
    position: relative;
    @include text-truncate;
    @include media-breakpoint-up(md) {
      min-width: 200px;
    }
    &:focus {
      outline: none;
    }

  }

  &-text {
    width: 100%;
    margin-left: 0;
    position: relative;
    font-size: $fontSize-normal;
    z-index: 10;
    @include media-breakpoint-up(md) {
      margin-left: 60px;
    }
    textarea {
      width: 100%;
      border: 1px solid transparent;
      transition: $transition-slow;
      &~.input-underline {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 10%;
        height: 2px;
        background-color: $actionBorder;
        transform: scale(0);
        transition: $transition-slow;
        z-index: 12;
      }
      &:focus {
        outline: none;
      }
    }
    .btn {
      padding: 0;
      padding-left: 15px;
      margin-left: -30px;
      position: absolute;
      top: -47px;
      right: 0;
      @include media-breakpoint-up(md) {
        top: -60px;
      }

      .material-icons {
        font-size: $fontSize-mega;
        transition: $transition-fast;
        color: $sendColor;
      }

      &--primary {
        background-color: $confirmColor;
        padding: 0;
        border-radius: 50%;
        height: 30px;
        @include row-center--center;
        width: 30px;
        span {
          display: none;
          @include media-breakpoint-up(lg) {
            display: block;

          }
        }
        @include media-breakpoint-up(lg) {
          width: auto;
          height: auto;
          border-radius: $borderRadius-small;
          padding: 10px;
        }

        .material-icons {
          color: $confirmColor-text;
          margin-right: 0;
          @include media-breakpoint-up(lg) {
            margin-right: 10px;
          }
        }
      }
    }
    &.change {
      position: relative;
      textarea {
        &~.input-underline {
          animation-name: input-strech;
          animation-duration: .4s;
          animation-fill-mode: forwards;
          z-index: 10;
        }
      }
    }

    textarea {
      width: 100%;
      border: $comment-simpleBorder;
      height: calc(4*14px + 6*1.75px);
      padding-right: 10px;

      &:focus {
        outline: none;
      }

    }
    margin-bottom: 10px;
  }
  &-required-note {
    position: absolute;
    bottom: -7px;
    left: 17px;
    font-size: $fontSize-mili;
    color: $noteColor;
  }
}

.comment-text {
  .comment-required-note {
    bottom: -20px;
    left: 0;
  }
}

@keyframes input-strech {
 0% {
   width: 0;
   transform: scale(0);
 }
  100% {
    width: 100%;
    transform: scale(1);
  }
}
