//variables
$border-background: $color-brand--tertiary;
$border-active: color('brand', 'primary');
$error: color('basic', 'red');
$labelColor: $color-brand--tertiary;
$iconColor: $color-brand--tertiary;
$iconColor-active: color('brand', 'primary');
$inputField: color('basic', 'white');
$inputFakeBackground: 0 0 0 9999px inset $inputField;
$inputFake-border: $border-thin solid color('basic', 'white') !important;

.validation-field {
  padding-bottom: 25px;
  position: relative;
  margin: 0 20px 20px 20px;
  &-wrapper {
    position: relative;
    .ember-power-select-trigger {
      width: 150px;
      @include media-breakpoint-up(sm) {
        width: 200px;
      }
    }
  }
  input:-webkit-autofill {
    box-shadow: $inputFakeBackground!important;
    background-color: $inputField !important;
    //border: 1px solid $inputField;
    border-top: $inputFake-border;
    border-left: $inputFake-border;
    border-right: $inputFake-border;
  }

  input:-webkit-autofill:focus {
    background-color: $inputField !important;
    border-top: $inputFake-border;
    border-left: $inputFake-border;
    border-right: $inputFake-border;
    box-shadow: $inputFakeBackground!important;
  }

  input:focus {
    background-color: $inputField;
    border-top: $inputFake-border;
    border-left: $inputFake-border;
    border-right: $inputFake-border;
    box-shadow: $inputFakeBackground;
  }

  input:not(.switcher-checkbox):not(.ember-power-select-trigger-multiple-input),
  textarea {
    border: $border-thin solid transparent;
    border-bottom: $border-thin solid $border-background;
    background-color: transparent;
    width: 100%;
    &:focus,
    &:active {
      outline: none;
      box-shadow: $inputFakeBackground;
      &+.input-underline {
        transform: scale(1);
        width: 100%;
      }
      &~.material-icons {
        color: $iconColor-active;
      }
    }
    &::placeholder {
      font-size: $fontSize-normal;
    }
  }
  &-label {
    font-size: $fontSize-normal;
    color: $labelColor;
  }
  .input-underline {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 10px;
    height: 2px;
    background: $border-active;
    transform: scale(0);
    transition: $transition-fast;
  }
  &-error-list {
    position: relative;
    width: 100%;
  }
  &-error-item {
    font-size: $fontSize-mili;
    color: $error;
    position: absolute;
    bottom: -17px;
    left: 0;
    display: none;
    &:first-of-type {
      display: block;
    }
  }
  .material-icons {
    position: absolute;
    bottom: 2px;
    right: 0;
    color: $iconColor;
    transition: $transition-medium;
    font-size: $fontSize-mega;
  }

  &-eye {
    position: absolute;
    right: 0;
    cursor: pointer;
  }
  &-status {
    height: 20px;
  }
}
