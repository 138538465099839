//variables
$activeBackground: color('basic', 'white');
$inactiveBackground: color('gray', 'lighten-3');
$tabBorder: $border-thin solid transparent;
$tabText: color('basic', 'black');
$tabText-inactive: $color-brand--tertiary;
$tabContent-background: color('basic', 'white');
$initiativeBox-border: $border-thin solid color('gray', 'background');
.tabs {
  @include column-start--flexStart;
  &-nav {
    @include column-center--center;
    @include media-breakpoint-up(sm) {
      @include row-center--center;
    }
  }

  &-nav-item {
    border: $tabBorder;
    background-color: $inactiveBackground;
    color: $tabText-inactive;
    padding: 8px;
    border-radius: $borderRadius-medium $borderRadius-medium 0 0;
    margin-bottom: 1px;
    font-size: $fontSize-mili;
    width: 100%;

    @media screen and (min-width: 480px) {
      margin-right: 20px;
      font-size: $fontSize-normal;
      padding: 10px 25px;
    }
    @include media-breakpoint-up(sm) {
      width: auto;
      margin-right: 15px;
    }
  }

  &-nav-item.active {
    background: $activeBackground;
    padding: 8px;
    color: $tabText;
    @media screen and (min-width: 480px) {
      padding: 10px 25px;
    }
    @include media-breakpoint-up(sm) {
      width: auto;
      transform: scale(1.2);
    }
  }

  &-content {
    width: 100%;
    padding: 25px;
    background-color: $tabContent-background;
    position: relative;

    .media-library-wrapper {
      overflow-x: auto;
      width: auto;
      @include media-breakpoint-down(sm) {
        align-items: center;
      }

      @include media-breakpoint-up(lg) {
        width: 100%;
        align-items: flex-start;
      }
      .media-library-header {
        width: auto;
        @include media-breakpoint-up(lg) {
          width: 100%;
        }
      }
      .no-media-wrapper {
        width: auto;
        @include media-breakpoint-up(lg) {
          width: 100%;
        }
      }
    }

    .filter-wrapper:after {
      background-color: transparent;
    }
    .initiative-catalog .initiative-box-content{
      border: $initiativeBox-border;
    }
  }
}
