/* activity feed variables */
$feed-background: lighten(color('brand', 'primary'), 50%);
$linkColor: color('gray', 'darken-2');
$linkColor-hover: darken($linkColor, 20%);
$timeColor: color('gray', 'text');
$activityFeed-heading: $color-brand--primary;
$eventColor: $color-brand--tertiary;
$activityFeed-border: $border-thin solid color('gray', 'background');
$feedBackground: color('basic', 'white');

.activity-feed {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 150px;
  min-width: 260px;
  width: 260px;
  background: $feed-background;
  height: calc(100vh - 170px);
  padding: 0 0 0 5px;
  transition: $transition-medium;
  z-index: $zIndex-9 + 1;
  border-radius: $borderRadius-large;
  box-shadow: $shadow-box;
  @include media-breakpoint-up(md) {
    width: 460px;
    padding: 0 10px 0 20px;
    height: calc(100vh - 170px);
  }

  &--narrow {
    box-shadow: 1px 1px 1px 1px transparent;
    right: -260px;
    background-color: $color-brand--primary;
    transition: $transition-fast;
    @include media-breakpoint-up(md) {
      right: -460px;
    }

    .btn--circle {
      transform: rotate(180deg);
      left: -20px !important;
      right: inherit;
      top: calc(50% - 20px) !important;
    }
    &:hover {
      right: -250px;
      @include media-breakpoint-up(md) {
        right: -450px;
      }
    }
  }

  .btn--circle {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 5px;
    top: 5px;
    transition: $transition-slow;
    @include row-center--center;
    width: 30px;
    height: 30px;
    z-index: $zIndex-5;
    padding: 0;
    @include media-breakpoint-up(sm) {
      left: -15px;
      top: calc(50% - 20px);
    }

    .material-icons {
      font-size: $fontSize-giga;
      margin: 0;
    }
    span {
      position: absolute;
    }
  }

  &-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border-bottom: $activityFeed-border;
    background: $feedBackground;
    padding: 10px 5px;
    min-height: 64px;
    @include media-breakpoint-up(sm) {
      padding: 20px;
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
  }

  &-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 5px;
  }

  &-link {
    color: $linkColor;
    transition: $transition-fast;

    &:hover {
      color: $linkColor-hover;
      text-decoration: none;
    }
    img {
      margin-right: 10px;
    }
  }
  &-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: $fontSize-mili;
    color: $timeColor;
    .material-icons {
      font-size: $fontSize-mili;
      margin-right: 5px;
    }
  }

  &-creator {
    margin-right: 5px;
  }

  &-event {
    color: $eventColor;
    margin-right: 5px;
  }
  &-text {
    width: 100px;
    @include text-truncate;
    margin: 0;
  }
  &-heading {
    font-weight: 500;
    color: $activityFeed-heading;
    text-align: center;
  }
}

.activity-feed-desktop {
  .activity-feed-text {
    width: 100px;
    @include text-truncate;
    @include media-breakpoint-up(sm) {
      width: 100%;
      overflow: initial;
      white-space: initial;
    }
  }
}

.activity-feed {
  .activity-feed-wrapper {
    display: block;
    .activity-feed-text {
      width: 110px;
      @include text-truncate;
    }
  }

  .activity-feed-creator,
  .activity-feed-event,
  .activity-feed-link {
    position: relative;
    float: left;
  }
}
