$border-onFocus: color('brand', 'primary');
$borderColor: color('basic', 'black');
.ember-text-area {
  max-height: 250px;
  height: 59px;
  width: 100%;
  border: $border-thin solid transparent;
  transition: $transition-fast;
  border-bottom: $border-thin solid $borderColor;
  resize: none;
  &~.input-underline {
    bottom: 3px;
  }
}


