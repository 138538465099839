$scrollBackground: #f5f5f5;

.scroll-data-load {
  position:relative;
  font-size: $fontSize-normal;
  @include column-center--center;
  margin-top: 20px;

  .image-changer {
    float: left;
  }
  .btn {
    padding: 0;
    margin-bottom: 10px;
  }
  &-body {
    display:flex;
    flex-direction:column;
    overflow-y: auto;
    max-height: calc(100vh - 170px);
    overflow-x: hidden;
    width: 100%;


    &::-webkit-scrollbar {
      background: transparent;
      @include media-breakpoint-up(lg) {
        background-color: $scrollBackground;
      }
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
      @include media-breakpoint-up(lg) {
        background-color: $color-brand--primary;
      }
    }
  }
  &-header {
    border-bottom: 1px solid color('gray', 'lighten-1');
    padding: 15px 10px;
    width: 100%;
    text-align: center;
    font-size: $fontSize-normal;
    cursor: pointer;
  }
}

//different scroll data options
.activity-feed {
  .scroll-data-load {
    margin-top: 0;
    max-height: calc(100vh - 170px);
    @include media-breakpoint-up(sm) {
      max-height: calc(100vh - 190px);
    }
  }
}

.tabs-content {
  .scroll-data-load {
    margin-top: 0;
    max-height: calc(100vh - 310px);
  }
}

