@font-face {
  font-family: 'Rubik';
  src: url('fonts/Rubik/font/Rubik-Black.woff2') format('woff2'),
  url('fonts/Rubik/font/Rubik-Black.woff') format('woff'),
  url('fonts/Rubik/font/Rubik-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('fonts/Rubik/font/Rubik-MediumItalic.woff2') format('woff2'),
  url('fonts/Rubik/font/Rubik-MediumItalic.woff') format('woff'),
  url('fonts/Rubik/font/Rubik-Black.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('fonts/Rubik/font/Rubik-LightItalic.woff2') format('woff2'),
  url('fonts/Rubik/font/Rubik-LightItalic.woff') format('woff'),
  url('fonts/Rubik/font/Rubik-Black.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('fonts/Rubik/font/Rubik-Light.woff2') format('woff2'),
  url('fonts/Rubik/font/Rubik-Light.woff') format('woff'),
  url('fonts/Rubik/font/Rubik-Black.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('fonts/Rubik/font/Rubik-Bold.woff2') format('woff2'),
  url('fonts/Rubik/font/Rubik-Bold.woff') format('woff'),
  url('fonts/Rubik/font/Rubik-Black.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('fonts/Rubik/font/Rubik-Regular.woff2') format('woff2'),
  url('fonts/Rubik/font/Rubik-Regular.woff') format('woff'),
  url('fonts/Rubik/font/Rubik-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('fonts/Rubik/font/Rubik-BoldItalic.woff2') format('woff2'),
  url('fonts/Rubik/font/Rubik-BoldItalic.woff') format('woff'),
  url('fonts/Rubik/font/Rubik-Black.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('fonts/Rubik/font/Rubik-Medium.woff2') format('woff2'),
  url('fonts/Rubik/font/Rubik-Medium.woff') format('woff'),
  url('fonts/Rubik/font/Rubik-Black.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('fonts/Rubik/font/Rubik-BlackItalic.woff2') format('woff2'),
  url('fonts/Rubik/font/Rubik-BlackItalic.woff') format('woff'),
  url('fonts/Rubik/font/Rubik-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('fonts/Rubik/font/Rubik-Italic.woff2') format('woff2'),
  url('fonts/Rubik/font/Rubik-Italic.woff') format('woff'),
  url('fonts/Rubik/font/Rubik-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
