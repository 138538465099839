$simpleBorder: 1px solid transparent;
$simpleBorder-active: 1px solid $color-brand--primary;
$selectBorder: $border-thin solid $color-brand--tertiary;
$selectText: $color-brand--tertiary;
$optionBackground: color('gray', 'separator');
$optionBackground--hover: color('gray','lighten-3');
.ember-power-select-multiple {
  color: $selectText;
  &-options {
    input {
      border-bottom: $simpleBorder;
    }
  }
  &-option {
    border: $simpleBorder;
    padding: 0 10px;
    font-size: $fontSize-normal;
    background-color: transparent;
    transition: $transition-medium;
    &:hover {
      background-color: $optionBackground--hover;
    }
  }
  &-trigger {
    border-bottom: $selectBorder;
    width: 100%;
    min-width: 200px;
    transition: $transition-medium;
    &[aria-expanded="true"] {
      border-bottom: $simpleBorder-active;
    }
  }
}

