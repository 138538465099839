//variables
$backgroundSingle: color('basic', 'white');
$notificationsColor: $color-brand--secondary;
$notificationsEvent: $color-brand--tertiary;

.notification {
  &-single {
    @include row-center--start;
    padding: 10px 20px;
    margin-bottom: 15px;
    background: $backgroundSingle;
    border-radius: $borderRadius-medium;
  }

  &-link {
    text-decoration: none;
    color: $notificationsColor;
  }

  &-popup {
    @include row-center--start;
    flex-wrap: wrap;
    position: relative;
  }

  &-avatar {
    margin-right: 10px;
  }

  &-author {
    font-weight: 500;
    margin-bottom: 0;

  }
  &-event {
    color: $color-brand--tertiary;
    margin-left: 5px;
    margin-bottom: 0;
  }

  &-text {
    margin-bottom: 0;
    max-width: 130px;
    @include text-truncate;
  }

  &-wrapper {
    min-height: 90px;
    position: fixed;
    width: 300px;
    bottom: 25px;
    right: 25px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    z-index: $zIndex-5;
    max-height: 400px;
    overflow: hidden;
    padding-top: 10px;
    &.active {
      display: flex;
    }
  }

  &-close {
    background: transparent;
    box-shadow: none;
    position: absolute;
    top: 0;
    right: 0;
    .btn {
      background: transparent;
      color: color('gray', 'lighten-1');
      box-shadow: none;
      padding: 0;
      position: absolute;
      top: 10px;
      right: 0;
      z-index: $zIndex-5 + 1;
    }
  }
}

.notification-list {
  .notification-text {
    max-width: 100%;
    margin-left: 5px;
  }

  .notification-single {
    position: relative;
  }

  .notification-date {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: $fontSize-mili;
  }
}


