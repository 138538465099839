//variables
$headerBackground-color: $color-brand--primary;
$headerText: color('basic', 'white');
$bodyBackground: color('basic', 'white');
$bodyText: $color-brand--secondary;
$bodyBorder: $border-thin solid color('gray', 'text');
.table {
  thead td{
    background: $headerBackground-color;
    color: $headerText;
    padding: 10px;
    text-transform: uppercase;
  }

  tbody td {
    background: $bodyBackground;
    color: $bodyText;
    padding: 10px;
    border-bottom: $bodyBorder;
  }

  .state {
    padding: 5px;
    text-transform: uppercase;
    color: color('basic', 'white');
    font-size: $fontSize-micro;
  }
  .active-state {
    background: color('basic', 'green');
  }

  .inactive-state {
    background: color('basic', 'red');
  }
}
