//variables
$footer-background: color('basic', 'white');
$cancelBtn-border: $border-thin solid color('basic', 'red');
$canceBtn-text: color('basic', 'red');
$cancelDanger: color('basic', 'red');
$cancelDanger-text: color('basic', 'white');
$cancelDanger-border: $border-thin solid transparent;

.form {
  form {
    //position: relative;
    padding-bottom: 60px;
    height: auto;
  }
  h3 {
    font-weight: 500;
  }
  &-content {
    @include column-center--spaceBetween;
    min-height: 100px;
    &.form-my-profile {
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      background-color: $footer-background;
      padding: 25px;
      border-radius: $borderRadius-medium;
      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }
      & ~ .form-footer {
        background-color: transparent;
        padding-right: 20px;
        bottom: 15px;
        left: -3px;

        @include media-breakpoint-up(md) {
          bottom: 15px;
          left: -20px;
        }
      }
      .file-upload-dropZone {
        height: 150px;
      }
      .media-text {
        font-size: $fontSize-giga;
        margin-bottom: 110px;
      }
      .file-upload {
        bottom: 30%;
      }
    }
    &-wrapper {
      margin-bottom: 30px;
    }
  }
  &-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    @include row-center--start;
    flex-direction: row-reverse;
    background: $footer-background;
    padding: 10px;
    border-radius: 0 0 $borderRadius-medium $borderRadius-medium;
    .btn {
      margin-left: 20px;
    }
  }
}

.subtitle {
  font-weight: 500;
  margin-top: 20px;
}

.user-form {
  &-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
  }
}

.user-image-wrapper {
  margin: 0 auto 20px auto;
}

.row-form {
  .col-6 {
    padding: 0;
  }
}
