.display-field {
  position: relative;
  margin: 0 20px 20px 20px;
  display: flex;
  flex-direction: column;

  &-label {
    width: 100%;
  }

  &-value {
    width: 100%;
  }
}