$boxShadow-regular: color('basic', 'black');
$modalButton-regular: color('gray', 'background');
$modalButton-regularHover: darken($modalButton-regular, 10%);
$modalButton-danger: color('basic', 'red');
$modalButton-dangerHover: darken($modalButton-danger, 10%);
$modalBackground: color('basic', 'white');
$modalHeader-background: $color-brand--primary;
$modalHeader-text: color('basic', 'white');
$modalBorder: $border-thin solid color('gray', 'separator');
$modalInfo-text: color('brand', 'secondary');
$modalFollowers-border: $border-thin solid transparent;
.dialog {
  &-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba($boxShadow-regular, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: $zIndex-7;
    padding: 25px;

    .form-content {
      max-height: 400px;
      overflow-y: auto;
      padding-top: 18px;
      margin: 70px 0 0 0;
      .color-picker {
        margin: 20px;
      }
      .content-wrapper {
        max-height: 250px;
        overflow-y: auto;

        .language-picker {
          margin-left: 20px;
          height: 30px;
        }
      }
    }

    .form-footer {
      border-top: $modalBorder;
      z-index: 10;
    }
    .display-field {
      margin-bottom: 50px;
      text-align: left;
      justify-self: flex-start;
    }
    .info-wrapper {
      @include row-center--flexEnd;
      padding-bottom: 15px;
      box-shadow: 0 3px 8px 0 rgba(40, 42, 47, .2);

      .info-content {
        @include row-start--flexStart;
        color: $modalInfo-text;
        font-size: $fontSize-normal;
        margin-right: 10px;

        .material-icons {
          font-size: $fontSize-kilo;
        }
      }
    }
  }

  &-box {
    position: relative;
    background: $modalBackground;
    border-radius: $borderRadius-large;
    box-shadow: 0 27px 24px 0 rgba($boxShadow-regular,.2), 0 40px 77px 0 rgba($boxShadow-regular,.22);
    animation: slideIn .7s;
    transform-origin: top;
    min-width: 250px;
    max-width: 300px;
    z-index: $zIndex-7;

    @include media-breakpoint-up(md) {
      min-width: 400px;
      max-width: 550px;
    }
  }

  &-box-small {
    .dialog-box {
      width: 320px;
      height: 250px;
    }
  }

  &-title {
    @include text-truncate;
    font-weight: 300;
    margin: 0 0 0 31px;
    max-width: 200px;
  }

  &-text {
    font-size: $fontSize-normal;
    margin-top: 45px;
  }

  &-header {
    @include row-center--spaceBetween;
    background-color: $modalHeader-background;
    border-radius: $borderRadius-large $borderRadius-large 0 0 ;
    padding: 20px;
    position: absolute;
    color: $modalHeader-text;
    z-index: $zIndex-1;
    .regular-box-title {
      margin-bottom: 0;
    }
    .btn {
      z-index: $zIndex-2;
      padding: 0;
      i {
        color: $modalHeader-text;
        font-size: 16px;
        transition: $transition-fast;
        &:hover {
          color: $modalButton-regularHover;
        }
      }
    }
  }

  &-content {
    position: relative;
    padding: 10px 20px 30px 20px;
    @include media-breakpoint-up(md) {
      padding: 10px 20px 10px 20px;
    }

    .row,
    .col-12 {
      padding: 0;
      margin: 0;
      overflow: hidden;
    }
  }

  &-followers {
    table tbody td{
      border-bottom: $modalFollowers-border;
      @include row-center--start;
      .image-changer {
        margin-right: 20px;
      }
      &:hover {
        cursor: default;
      }
    }
    .form-content {
      margin: 45px 0 10px;
    }
  }

  &-delete {
    .dialog-header {
      padding: 10px 15px;
    }
  }

  //large dialog
  &--large .dialog-box{
    max-width: 350px;
    @include media-breakpoint-up(md) {
      max-width: 700px;
    }
    .dialog-content {
      max-height: 460px;
      height: 460px;

      @include media-breakpoint-up(md) {
        max-height: 670px;
        height: 670px;
      }

      @media only screen
      and (min-device-width: $mobile-landscape-min)
      and (max-device-width: $mobile-landscape-max)
      and (orientation: landscape) {
        max-height: 100%;
      }
      @media (min-width: $tablet-landscape-min)
      and (max-width: $tablet-landscape-max)
      and (orientation: landscape) {
        //max-height: 350px;
        //height: 350px;
      }

      .image-changer {
        .dialog--upload {
          .dialog-box {
            //height: 150px;
          }
          .form-content {
            height: 380px;
            max-height: 380px;
          }
          .dialog-content {
            max-height: 515px;
            @media only screen
            and (min-device-width: $mobile-landscape-min)
            and (max-device-width: $mobile-landscape-max)
            and (orientation: landscape) {
              max-height: 100%;
            }
            @media (min-width: $tablet-landscape-min)
            and (max-width: $tablet-landscape-max)
            and (orientation: landscape) {
              max-height: 350px;
              height: 350px;
            }
            .form-content {

              @media only screen
              and (min-device-width: $mobile-landscape-min)
              and (max-device-width: $mobile-landscape-max)
              and (orientation: landscape) {
                max-height: 200px;
                height: 200px;
              }
              @media (min-width: $tablet-landscape-min)
              and (max-width: $tablet-landscape-max)
              and (orientation: landscape) {
                max-height: 230px;
                height: 230px;
              }
            }

          }
          .dialog-header .btn {
            z-index: $zIndex-2 + 1;
          }
          .file-upload-dropZone{
            max-height: 150px;
            span {
              display: none;
              @include media-breakpoint-up(md) {
                display: block;
                font-size: $fontSize-mega;
                margin-bottom: 120px;
              }
            }
          }
        }
      }
    }
    .form-content {
      height: 300px;
      max-height: 300px;

      @include media-breakpoint-up(md) {
        max-height: 505px;
        height: 505px;
      }

      @include media-breakpoint-up(lg) {
        height: 505px;
        max-height: 505px;
      }
      @media only screen
      and (min-device-width: $mobile-landscape-min)
      and (max-device-width: $mobile-landscape-max)
      and (orientation: landscape) {
        height: 120px;
      }
      @media only screen
      and (min-device-width: $tablet-landscape-min)
      and (max-device-width: $tablet-landscape-max)
      and (orientation: landscape) {
        //max-height: 180px;
      }
    }
    .content-wrapper {
      max-height: 400px;
      max-width: 300px;
      width: 100%;
      @include media-breakpoint-up(md) {
        max-width: 700px;
        max-height: 700px;
      }
      @media only screen
      and (min-device-width: $mobile-landscape-min)
      and (max-device-width: $mobile-landscape-max)
      and (orientation: landscape) {
        max-height: 130px;
      }
      @media only screen and (min-width: $tablet-landscape-min) and (max-width: $tablet-landscape-max),
      only screen and (min-device-width: $tablet-landscape-min)
      and (max-device-width: $tablet-landscape-max)
      and (orientation: landscape) {
        max-height: 100%;
      }
    }
  }

  //small dialog
  &--small {
    .dialog-box{
      max-width: 180px;
      max-height: 180px;
      height: 180px;

      .dialog-content {
        max-height: 175px;
        height: 175px;
      }
      .form-content {
        height: 175px;
        max-height: 175px;
      }

      .content-wrapper {
        max-height: 180px;
      }
    }
    .dialog-header {
      padding: 5px 10px;
    }
  }


  //upload
  &--upload {
    .dialog-box{
      min-height: 305px;
      max-width: 320px;
      @media only screen
      and (min-device-width: $mobile-landscape-min)
      and (max-device-width: $mobile-landscape-max)
      and (orientation: landscape) {
        max-height: 300px;
      }
    }
    .file-upload-dropZone-wrapper {
      .upload-image {
        margin-top: 0;
      }
    }
  }
}

// TODO animations should be in separate file, so that they can be easily reused. @dkosijer Think about the best way for this
@keyframes slideIn {
  0% {
    margin-top:  25px;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    margin-top: 0;
  }
}
