//variables


.small-info {
  &-wrapper {
    @include row-center--start;
    flex-wrap: wrap;
    margin: 10px 0;
  }
  &-single {
    @include row-center--start;
    width: auto;
    margin-right: 25px;
  }
}

