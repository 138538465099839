// basic colors
$basic: (
        "red":   #f44336,
        "orange": #ff9800,
        "green": #4caf50,
        "white": #fff,
        "black": #000,
);

//brand colors
$brand: (
        "primary":   #296cf9, //main blue
        "secondary": #212429, //black
        "tertiary":  #8c98b2, //blue gray
);

//shades of gray colors
$gray: (
        "text":      #c6ccd1,
        "separator": #dcdcdf,
        "background": #f4f6f6,
        //other gray - define
        "lighten-3": #eeeeee,
        "lighten-2": #dce1e4,
        "lighten-1": #bdbdbd,
        "darken-1":  #74767d,
        "darken-2":  #555,
        "darken-3":  #3C4858,
        "darken-4":  #212121

);

$colors: (
        "basic": $basic,
        "gray":  $gray,
        "brand": $brand,
) !default;

// usage: color("name_of_color", "type_of_color")

@function color($color, $type) {
  @if map-has-key($colors, $color) {
    $curr-color: map-get($colors, $color);
    @if map-has-key($curr-color, $type) {
      @return map-get($curr-color, $type);
    }
  }
  @warn "Unknown `#{$color}` - `#{$type}` in $colors.";
  @return null;
}

//brand variables
$color-brand--primary: color('brand', 'primary');
$color-brand--secondary: color('brand', 'secondary');
$color-brand--tertiary:  color('brand', 'tertiary');

//diagonal gradient
$diagonal-background--primary: linear-gradient(to top left, $color-brand--primary, lighten($color-brand--primary, 10));
$diagonal-background--secondary: linear-gradient(to top left, $color-brand--secondary, lighten($color-brand--secondary, 20));





