$boxShadow-regular: color('basic', 'black');
.timeline-status {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include media-breakpoint-up(sm) {
    align-items: center;
    padding: 10px 40px 10px 0;
  }
  @include media-breakpoint-up(md) {
    padding: 10px 90px 10px 0;
    flex-direction: row;
  }

  &-part {
    display: flex;
    flex-direction: column;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
    padding: 0 20px;

    h4 {
      margin: 0 0 10px 0;
    }
    @include media-breakpoint-up(md) {
      justify-content: space-between;
      h4 {
        margin: 0;
      }
    }
  }
  &-form {
    position: relative;
    margin-bottom: 10px;

    .btn {
      position: absolute;
      padding: 5px;

      i {
        margin-right: 0;
        font-size: 14px;
        @include media-breakpoint-up(sm) {
          font-size: 22px;
        }
      }

      &:first-of-type {
        position: absolute;
        right: 40px;
        top: 19px;
        background: color('basic', 'green');
        color: color('basic', 'white');
        @include media-breakpoint-up(md) {
          right: 60px;
        }
      }

      &:last-of-type {
        position: absolute;
        right: 10px;
        top: 19px;
        background: color('basic', 'red');
        color: color('basic', 'white');
        @include media-breakpoint-up(md) {
          right: 20px;
        }
      }
    }
  }

  .validation-field {
    margin: 0;
    padding-bottom: 0;
    @include media-breakpoint-up(sm) {
      margin: 0 20px 20px 20px;
    }
  }

  &-error {
    position: absolute;
    bottom: -5px;
    left: 260px;
    color: color('basic', 'red');
    font-size: $fontSize-mili;
  }

  &-period-wrapper {
    cursor: pointer;
    position: relative;
    width: 180px;
    margin-top: 20px;
    font-size: $fontSize-normal;
    @include media-breakpoint-up(sm) {
      width: 200px;
    }
    @include media-breakpoint-up(md) {
      margin-top: 0;
      width: 220px;
      font-size: $fontSize-kilo;
    }

    .material-icons {
      position: absolute;
      right: -30px;
      top: -5px;
    }
  }

  &-conflict-days {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: $fontSize-normal;
    margin: 5px 0;

    &-wrapper {
      display: flex;
      flex-direction: column;
    }

    &-message {
      margin-bottom: 10px;
      width: 100%;
    }

    &-single {
      font-size: $fontSize-normal;
      margin-bottom: 10px;

      .date {
        font-style: italic;
        position: relative;
        margin-right: 5px;

        &:after {
          content: ',';
          position: absolute;
          top: 0;
          right: -4px;
        }

        &:last-child {
          &:after {
            content: '';
          }
        }
      }

      .status-name {
        font-weight: 500;
      }
    }

    &-note {
      color: color('basic', 'red');
      font-size: $fontSize-normal;
      margin-bottom: 5px;
    }
  }

}

.timeline-status.confirmed-status {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($boxShadow-regular, 0.3);
  }
}

.timeline-status-part {
  .dialog-box {
    .btn {
      right: inherit;
      top: inherit;
      position: relative;
      padding: 10px 20px;
      background: initial;
    }
    .btn--secondary {
      background: color('brand', 'secondary');
      color: color('basic', 'white')
    }
    .btn--danger {
      background: color('basic', 'red');
      color: color('basic', 'white');
    }
  }
}
