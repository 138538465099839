//variables
$tableBackground: color('basic', 'white');
$headerBackground: color('brand', 'primary');
$selectBackground-hover: color('brand', 'primary');
$selectBorder: color('brand', 'primary');
$iconColor: $color-brand--tertiary;
$iconColor-hover: color('basic', 'white');
$borderTable: color('gray', 'lighten-3');
$fontColor-light: color('basic', 'white');
$paginationShadow: $shadow-box;
$border-background-light: color('gray', 'separator');
$linkColor: $color-brand--primary;
$linkHover: darken($color-brand--primary, 10%);
$editHover: $color-brand--primary;
$deleteHover: color('basic', 'red');
$disabledColor-border: 1px solid color('gray', 'text');
$disabledColor-text: color('gray', 'text');

.models-table {
  &-wrapper {
    background: $tableBackground;
    font-size: $fontSize-normal;
    padding: 50px 20px 20px 20px;
    margin: 20px 0;
    border-radius: $borderRadius-large;
    position: relative;
    overflow: auto;
    .table {
      overflow-x: auto;
      width: 100%;
      //display: block;
      .material-icons {
        color: $iconColor;
        font-size: $fontSize-mega + 2;
        transition: $transition-fast;
      }
      .material-edit {
        &:hover {
          color: $editHover;
        }
      }
      .material-delete {
        &:hover {
          color: $deleteHover;
        }
      }
      a {
        transition: $transition-fast;
        color: $linkColor;
        &:hover {
          color: $linkHover;
        }
        &:visited {
          color: $linkColor;
        }
        &:focus,
        &:active {
          color: $linkColor;
        }
      }
      .btn {
        padding: 10px 0;
      }
    }
    .form-group {
      position: absolute;
      top: 15px;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 202px;
        height: 2px;
        background-color: $border-active;
        transform: scale(0);
        transition: $transition-fast;
        font-size: $fontSize-mili;
      }
      &:before {
        content: '\e8b6';
        font-family: 'Material Icons';
        position: absolute;
        top: 0;
        left: 0;
        color: $border-background-light;
        font-size: 14px;
      }

      .clearFilterIcon {
        position: absolute !important;
        top: 5px;
        color: color('basic', 'red');
        right: 20px;
        font-size: $fontSize-mili;
      }

      input {
        border: 1px solid transparent;
        border-bottom: 1px solid $border-background-light;
        padding-left: 25px;
        padding-bottom: 5px;
        max-width: 160px;
        font-size: $fontSize-normal;
        &:focus {
          outline: none;
          & ~ .form-group:after {
            transform: scale(1);
          }
        }
      }
    }
    .table-header {
      text-transform: uppercase;
      font-size: $fontSize-mili;
      .glyphicon {
        position: relative;
        &:after {
          font-family: 'Material Icons';
          position: absolute;
          font-style: normal;
          top: 0;
          left: 10px;
        }
        &-triangle-top {
          &:after {
            content: '\e5c7';
          }
        }
        &-triangle-bottom {
          &:after {
            content: '\e5c5 ';
          }
        }
      }
    }
    label {
      position: relative;
      display: none;
    }
    thead {
      background-color: $headerBackground;
      color: $tableBackground;
      th {
        padding: 10px;
      }
    }
    .table-footer {
      margin-top: 20px;
      select {
        margin-top: 5px;
        width: 44px;
        height: 35px;
        background: transparent;
        border: $border-thin solid $selectBorder;
        color: $iconColor;
        transition: $transition-fast;
        &:hover {
          cursor: pointer;
          border: 1px solid transparent;
          background: $selectBackground-hover;
          color: $iconColor-hover;
          box-shadow: $paginationShadow;
        }
        &:focus {
          outline: none;
        }
        option {
          &:hover {
            background-color: $selectBackground-hover;
          }
        }
      }
      .btn-group {
        height: 45px;
        align-items: center;
      }
      .table-summary {
        @include row-center--start;
      }
      .table-nav {
        text-align: right;
        right: 10px;
      }

      .clearFilters {
        //hiding clear buttons from footer
        display: none;
      }
    }
    tbody {
      td {
        padding: 10px;
        font-size: $fontSize-normal;
      }
      tr {
        border-bottom: 1px solid $borderTable;
      }

    }
    .table-footer {
      .btn {
        padding: 0 15px;
      }
      .row {
        @include column-start--flexStart;
        @include media-breakpoint-up(md) {
          flex-direction: row;
          align-items: center;
        }
      }
      .btn-group {
        @include row-center--start;
        @include media-breakpoint-up(md) {
          justify-content: flex-end;
        }
      }
      .glyphicon {
        position: relative;
        &:after {
          font-family: 'Material Icons';
          content: '';
          position: absolute;
          top: -15px;
          left: 0;
          color: $iconColor;
          font-style: normal;
          font-size: $fontSize-normal;
          border-radius: 50%;
          padding: 7px;
          width: 30px;
          height: 30px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          transition: $transition-fast;
        }
        &:hover {
          &:after {
            cursor: pointer;
            border: 1px solid transparent;
            background: $selectBackground-hover;
            color: $iconColor-hover;
            box-shadow: $paginationShadow;
          }
        }
        &-menu-left {
          &:after {
            content: '\e5cb';
          }
        }
        &-menu-right {
          &:after {
            content: '\e5cc';
          }
        }
        &-chevron-right {
          &:after {
            content: '\e5dd';
            font-size: 18px;
          }
        }
        &-chevron-left {
          &:after {
            content: '\e5dc';
            font-size: 18px;
          }
        }
        &-remove-circle {
          &:after {
            content: '\e5cd';
            top: -15px;
          }
        }
      }
    }
    .glyphicon {
      &-remove-sign {
        position: relative;
        &:after {
          content: '\e5cd';
          font-family: 'Material Icons';
          position: absolute;
          top: 0;
          right: 0;
          font-style: normal;
        }
      }
    }
    .disabled {
      .glyphicon {
        &:hover {
          &:after {
            border: $disabledColor-border;
            background-color: $disabledColor-text;
            box-shadow: $paginationShadow;
          }
        }
      }
    }
  }
}

//row with search no padding
.models-table-wrapper {
  .table {
    thead {
      tr:nth-child(2) {
        height: 0;
        font-size: 0;
        margin: 0;
        th {
          height: 0;
          padding: 0;
          font-size: 0;
          margin: 0;
        }
      }
      .globalSearch {
        position: absolute!important;
      }
    }
  }
}

