//variables
$paginationColor: $color-brand--tertiary;
$pageActive: $color-brand--primary;
$pageText: color('basic', 'white');
.custom-pagination {
  @include row-center--spaceBetween;
  color: $paginationColor;
  font-size: $fontSize-mili;

  &-per-page {
    @include row-center--start;

    label {

    }
    .ember-power-select-trigger {
      width: 46px;
      padding: 0;
      height: 30px;
    }
  }

  &-nav {
    display: flex;

    .btn {
      padding: 0;
      text-transform: uppercase;
      color: color('brand', 'tertiary');
    }
  }

  &-pages {
    display: flex;
    align-items: center;
    @include row-center--spaceEvenly;
    cursor: pointer;
  }

  &-page.active {
    background: $pageActive;
    border-radius: 50%;
    color: $pageText;
    margin: 2px;
  }

  &-page {
    @include row-center--center;
    width: 20px;
    height: 20px;

    &:last-child {
      margin-right: 5px;
    }
    &:first-child {
      margin-left: 5px;
    }
  }
}
