//variables
$boxBackground: color('basic', 'white');
$spanText: color('basic', 'white');
$materialIcon-meta: color('gray', 'separator');
$averageText: color('basic', 'white');
$averageIcons: color('basic', 'orange');
$overallText: color('basic', 'white');
$boxBorder: $border-thin solid color('gray', 'separator');
$metaItem-color: $color-brand--secondary;
$metaItem-icon: $color-brand--tertiary;
$numberColor: $color-brand--secondary;
$overlay: rgba($color-brand--secondary, .5);
.initiative-box {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  border-radius: $borderRadius-medium;

  &-header {
    min-height: 160px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: $borderRadius-medium $borderRadius-medium 0 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px;
    z-index: 0;
    @include media-breakpoint-up(sm) {
      justify-content: flex-start;
      align-items: flex-start;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $overlay;
      z-index: -1;
      border-radius: $borderRadius-medium $borderRadius-medium 0 0;
    }

    &:focus {
      outline: none;
    }

    &-meta {
      @include row-center--start;

      .initiative-box-meta {
        @include row-center--start;
        margin-right: 5px;
        position: relative;
        width: auto;

        span {
          margin-right: 5px;
          position: relative;
          color: $spanText;
          font-size: $fontSize-normal;

          &:after {
            content: ',';
            position: absolute;
          }
          &:last-of-type:after {
            content: '';
          }
        }

        .material-icons {
          font-size: 15px;
          margin-right: 3px;
          color: $materialIcon-meta;
        }
      }
    }

    .btn--primary {
      display: flex;
      position: relative;
      z-index: $zIndex-3;

      @include media-breakpoint-up(sm) {
        position: absolute;
        bottom: 15px;
        right: 15px;
      }
    }
  }

  &-average {
    position: absolute;
    bottom: inherit;
    left: inherit;
    right: 10px;
    top: 10px;
    display: flex;
    color: $averageText;
    z-index: $zIndex-1;
    writing-mode: vertical-rl;
    text-orientation: upright;
    font-size: 12px;
    align-items: baseline;

    @include media-breakpoint-up(sm) {
      writing-mode: initial;
      text-orientation: initial;
      font-size: 17px;
      align-items: flex-end;
    }

    @include media-breakpoint-up(sm) {
      bottom: 15px;
      left: 15px;
    }

    .material-icons {
      color: $averageIcons;
      margin-right: 5px;
      font-size: $fontSize-mega;
    }
  }

  &-title {
    width: 100%;
    color: $overallText;
    font-weight: 500;
    font-size: $fontSize-mega;
    overflow: hidden;
    margin-bottom: 10px;
    z-index: $zIndex-2;
    max-width: 135px;
    @media(min-width: 400px) {
      max-width: 230px;
    }
    @include text-truncate;
    @include media-breakpoint-up(sm) {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-width: 100%;
    }

    &:hover,
    &:active {
      color: $overallText;
      outline: none;
    }
  }

  &-content {
    border-radius: 0 0 $borderRadius-medium  $borderRadius-medium;
    background: $overallText;
    padding: 15px;
    display: flex;
    flex-direction: column;
    min-height: 210px;
    position: relative;
    justify-content: space-between;

    .voting-finished {
      position: absolute;
      top: 50px;
      right: 11px;
      color: color('basic', 'red');
      font-size: $fontSize-normal;
      padding: 4px;
      border: 1px solid color('basic', 'red');
      transform: rotate(15deg);
      background: rgba(color('basic', 'white'), 0.8);
    }
  }

  &-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
  }

  &-desc {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: $fontSize-normal;
  }

  &-meta {
    @include column-start--spaceBetween;
    margin-bottom: 15px;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  &-meta-item {
    display: flex;
    font-size: $fontSize-mili;
    align-items: center;
    color: $metaItem-color;
    margin-right: 10px;

    a {
      display: flex;
      align-items: center;
      color: $metaItem-color;
    }

    .material-icons {
      font-size: 14px;
      margin-right: 3px;
      color: $metaItem-icon;
    }

    .liker {
      margin-left: 0;

      button {
        margin: 0 5px;
      }
    }

    &:last-child{
      margin-right: 0;
    }
  }

  &-meta-item.meta-action {
    cursor: pointer;
    pointer-events: auto;
  }

  &-footer {
    padding-top: 10px;
    margin-top: 0;
    border-top: $boxBorder;
    order: 3;
    @include row-center--spaceBetween;
    flex-wrap: wrap;
    @media screen and (min-width: 480px) {
      padding-top: 15px;
      margin-top: 15px;
    }
    .material-icons {
      padding: 2px;
      font-size: $fontSize-kilo;
      @include media-breakpoint-up(sm) {
        padding: 0;
      }
    }
    .number-wrapper {
      font-size: $fontSize-normal;
      color: $numberColor;
    }
  }
}
