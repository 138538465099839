//variables
$titleBackground: color('basic', 'white');
.highcharts {
  &-wrapper:not(.filter-bar) {
    background: $titleBackground;
  }
  &-wrapper {
   margin-bottom: 50px;
  }
  &-title-custom {
    .title {
      text-align: center;
      background: $titleBackground;
      padding: 20px;
      margin-bottom: 0;
    }
  }
}
