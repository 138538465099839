//variables
$sliderInactive: color('basic', 'white');
$sliderActive-background: color('gray', 'background');
$defaultText-color: color('basic', 'white');
$slider-arrows: $color-brand--primary;

.slider {
  width: 100%;
  position: relative;
  margin: 25px 0;
  display: flex;
  background: $sliderActive-background;
  overflow-x: hidden;
  min-height: 400px;
  @include media-breakpoint-up(md) {
    margin: 50px 0;
  }

  .btn {
    position: absolute;
    right: 20px;
    left: inherit;
    top: 90%;
    transform: translateY(-50%);
    padding: 0;
    height: 20px;
    width: 40px;
    border-radius: 0;
    z-index: 10;
    &:hover {
      cursor: pointer;
    }
    @include media-breakpoint-up(md) {
      top: 50%;
    }

    &:first-of-type {
      left: 20px;
      right: inherit;
    }

    .material-icons {
      color: $defaultText-color;
      font-weight: 500;
      font-size: $fontSize-tera;
      background: rgba($defaultText-color, .5);
      border-radius: 50%;
    }
  }

  &-content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
  }
  .initiative-box-footer {
    min-height: 55px;
  }

}

.slide {
  width: 100%;
  display: none;
  height: 400px;
  transition: $transition-slow;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 8;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    z-index: -1;
  }

  &-meta-wrapper {
    @include column-center--flexStart;
    width: auto;
    margin-bottom: 5px;
    min-height: 35px;
    @include media-breakpoint-up(sm) {
      @include row-center--center;
      margin-bottom: 20px;
    }
  }

  &-title {
    font-size: $fontSize-kilo;
    margin-bottom: 20px;
    color: $defaultText-color;
    width: 100%;
    font-weight: 500;
    text-align: center;

    @include media-breakpoint-up(sm) {
      font-size: $fontSize-giga;
      margin-bottom: 10px;
    }
    @include media-breakpoint-up(lg) {
      font-size: $fontSize-tera;
    }

    &:hover {
      color: color('basic', 'white');
    }
  }

  &-meta {
    @include column-center--center;
    font-size: $fontSize-normal;
    margin-bottom: 10px;
    width: auto;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }

    > span {
      margin-right: 5px;
      position: relative;
      color: color('basic', 'white');
      &:after {
        content: ',';
        position: absolute;
      }

      button {
        margin-right: 20px;
      }
      &:last-of-type:after {
        content: '';
      }
    }
  }

  &-meta-single {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-right: 5px;
    color: $defaultText-color;
    margin-bottom: 5px;


    @include media-breakpoint-up(sm) {
      margin-bottom: 0;
    }
  }

  &-desc {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    width: 100%;
    color: $defaultText-color;
    text-align: center;
    max-width: 400px;
    @include media-breakpoint-up(md) {
      max-width: 550px;
    }
    @include media-breakpoint-up(xl) {
      max-width: 700px;
    }
  }

  .slide-status {
    display: none;
  }

  .progress-status-all {
    @include row-center--start;
  }
  .progress-bar-title {
    text-transform: uppercase;
    font-weight: 500;
    color: $defaultText-color;
    margin-bottom: 15px;
  }
  .progress-status-wrapper {
    align-items: center;
  }
}

.slide {
  &.active-slide{
    height: 400px;
    @include column-center--center;
    .slider-content-wrapper {
      animation: slider-right .4s linear;
      transform-origin: left;
    }

    &.slide-left {
      .slider-content-wrapper {
        animation: slider-left .4s linear;
        transform-origin: right;
      }
    }

    .slide-status {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;
    }

    .initiative-box-footer {
      border: none;
      display: flex;
      justify-content: center;
      padding: 0;
    }

    .initiative-box-meta-item {
      color: $defaultText-color;
      cursor: default;
      padding-right: 15px;
    }

    .material-icons {
      color: $defaultText-color;
      font-size: 17px;
      margin-right: 5px;
    }
  }
}


.empty-slide {

  background: color('gray', 'darken-1');
  min-height: 400px;
  width: 100%;

  &:after {
    font-family: "Material Icons";
    content: '\e8a7';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $sliderInactive;
    font-size: 170px;
    z-index: 1;
  }
  &-info {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    font-size: $fontSize-giga;
    color: $defaultText-color;
  }
}

@keyframes slider-right {
  0% {
    transform: translateX(-200px);
    filter: blur(15px);
    opacity: 0;
  }
  50% {
    transform: translateX(-50px);
    filter: blur(7px);
    opacity: .8;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
    filter: blur(0);
  }
}

@keyframes slider-left {
  0% {
    transform: translateX(200px);
    filter: blur(15px);
    opacity: 0;
  }
  50% {
    transform: translateX(50px);
    filter: blur(7px);
    opacity: .8;
  }
  100% {
    transform: translateX(0);
    filter: blur(0);
    opacity: 1;
  }
}
