$pickerShadow: $shadow-box;
$pickerIcon-color: $color-brand--tertiary;
.color-picker {
  //width: 100%;
  //display: flex;
  //flex-direction: column;
  @include column-start--flexStart;

  &-input-wrapper {
    width: 160px;
    position: relative;
    border-radius: 5px;
    &:hover {
      cursor: pointer;
    }
  }

  label {
    display: flex;
    width: 100%;
    margin-bottom: 5px;
  }

  input {
    width: 160px;
    border: $border-thin solid transparent;
    background-color: transparent;
    padding: 5px 10px;
    &:focus {
      outline: none;
    }
  }

  &-wrapper {
    width: 195px !important;
    @include row-center--start;
    flex-wrap: wrap;
    height: 110px;
    overflow-y: auto;
    padding: 10px 0 0 10px;
    margin-top: 4px;
    box-shadow: $pickerShadow;
    border-radius: 5px;
  }

  &-color-box {
    min-width: 30px;
    height: 20px;
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    border-radius: 3px;
    box-shadow: $pickerShadow;
  }

  &-selected-color {
    position: absolute;
    right: -1px;
    top: 0;
    width: 50px;
    min-width: 20px;
    height: 30px;
    border-radius: 0 5px 5px 0;
  }
  &-icon {
    position: absolute;
    bottom: 5px;
    right: -35px;
    color: $pickerIcon-color;
  }
}
