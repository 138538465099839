.initiative-catalog {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .pagination-wrapper {
    display: block;
    width: 100%;
  }
    &-meta-item {
      margin-bottom: 10px;
    }

  &--row {
    flex-direction: column;

    .initiative-box-content {
      min-height: inherit;
    }
    .initiative-box {
      max-width: 100%;
      flex-direction: column;
      height: auto;
      min-height: 45px;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }
    }

    .initiative-box-header {
      @include row-bottom--spaceBetween;
      width: 100%;
      min-width: 150px;
      min-height: 162px;
      border-radius: $borderRadius-medium $borderRadius-medium 0 0;

      &:after {
        border-radius: $borderRadius-medium $borderRadius-medium 0 0;
        @include media-breakpoint-up(lg) {
          border-radius: $borderRadius-medium 0 0 $borderRadius-medium;
        }
      }

      @include media-breakpoint-up(lg) {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 150px;
        min-width: 162px;
        border-radius: $borderRadius-medium 0 0 $borderRadius-medium;
      }

      .btn--primary {
        position: relative;
        top: 0;
        right: 0;
      }
    }

    .initiative-box-header-meta .initiative-box-meta span {
      color: color('gray', 'text');
      font-size: $fontSize-mili;
    }

    .initiative-box-header-meta {
      width: auto;
      order: 2;
    }

    .initiative-box-header-wrapper {
      @include row-center--start;
      margin-top: 15px;

      .initiative-box-meta-item {
        margin-bottom: 0;
      }
    }

    .initiative-box-average {
      left: 10px;
      bottom: inherit;
      top: 10px;
      position: absolute;

      @include media-breakpoint-up(lg) {
        left: inherit;
        bottom: inherit;
        position: relative;
      }
    }

    .initiative-box-title {
      color: color('brand', 'secondary');
      font-size: $fontSize-mega;
      font-weight: 500;
      margin-bottom: 0;

      &:hover,
      &:active {
        color: color('brand', 'secondary');
      }

    }
    .initiative-box {
      min-height: 45px;
    }

    .initiative-box-info {
      position: relative;
      order: 1;
      @include row-center--start;
      width: auto;
      flex-wrap: wrap;
    }

    .initiative-box-desc {
      order: 2;
    }

    .initiative-box-content {
      width: 100%;
      position: relative;
      height: auto;
      border-radius: 0 0 $borderRadius-medium $borderRadius-medium;

      @include media-breakpoint-up(lg){
        width: calc(100% - 200px);
        border-radius: 0 $borderRadius-medium $borderRadius-medium 0;
      }

      .initiative-box-header-meta {
        margin-left: 10px;
      }
    }

    .initiative-box-status {
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid transparent;

      .initiative-box-text {
        margin-right: 15px;
      }

    }

    .initiative-box-footer {
      justify-content: flex-start;
      border-top: none;
      margin-top: 0;

      .initiative-box-meta-item {
        margin-right: 10px;
      }
    }
  }
}

