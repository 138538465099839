/* sidebar variables */
$sidebar-background: lighten(color('brand', 'primary'), 50%);
$sidebar-color: color('brand', 'primary');
$logoBurger-background: color('gray', 'background');
//burger style
$burger-background: color('brand', 'secondary');
//icons style
$iconColor-default: color('gray', 'darken-1');

.side-bar {
  position: fixed;
  top: 0;
  left: 0;
  background: $sidebar-background;
  color: $sidebar-color;
  width: $sideBar-width;
  height: 100vh;
  transition: $transition-medium;
  z-index: 401;

  .btn {
    transition: $transition-fast;
    span {
      width: 20px;
      position: relative;
    }
    &:after {
      content: '\e5cb';
      font-family: "Material Icons";
      position: absolute;
      top: 2px;
      left: -17px;
      color: $color-brand--tertiary;
      font-size: $fontSize-mega + 2px;
    }
  }

  &-logoBurger {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 60px;
    padding: 10px;
    transition: $transition-medium;
    width: 100%;
    background: $logoBurger-background;

    .logo-wrapper {
      @include row-center--center;
      img {
        &:first-child {
          width: auto;
          height: 40px;
        }
        &:nth-child(2) {
          height: 28px;
          margin-left: 10px;
        }
      }
    }


    a {
      @include row-center--center;
      position: relative;
    }
  }
  &-logo {
    transition: $transition-slow;
  }
  &-burger {
    position: relative;
    display: block;
    width: 20px;
    height: 2px;
    background: $burger-background;
    z-index: $zIndex-always-onTop;

    &:before {
      content: '';
      position: absolute;
      width: 20px;
      height: 2px;
      background: $burger-background;
      left: 0;
      top: 10px;
      transition: $transition-fast;
    }
    &:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 2px;
      background: $burger-background;
      left: 0;
      bottom: -5px;
      transition: $transition-fast;
      opacity: 1;
    }
  }

  .btn--simpleReverse {
    padding: 0;
    width: 25px;
    height: 18px;
    position: absolute;
    right: -45px;
    top: 20px;
    display: none;

    @include media-breakpoint-up(lg) {
        display: block;
    }
  }

  &--narrow {
    width: 60px;

    .sideBar-tooltip {
      display: none;
      position: absolute;
      top: 0;
      left: 35px;
      background: $color-brand--secondary;
      color: color('basic', 'white');
      font-size: 10px;
      white-space: nowrap;
      padding: 0 5px;
      border-radius: 3px;
    }

    .sideMenu-link-wrapper:hover .sideBar-tooltip {
      display: block;
    }

    .logo-img-text {
      display: none;
    }

    .side-bar-logo {
      opacity: 0;
      margin-left: 60px;
      transition: $transition-fast;
    }
    .sideMenu-wrapper {
      overflow-y: initial;
    }
    .sideMenu-link-wrapper {
      //overflow-x: hidden;
      margin-bottom: 2px;

      .sideMenu-icon {
        font-size: 20px;
        padding-right: 0;
        padding-left: 5px;
      }
      span {
        display: none;
      }
      &:hover {
        border-radius: $borderRadius-medium;
      }
    }

    .btn {
      transform: rotate(180deg);
      top: 25px;
    }
  }
}
