$sideBar-background: color('basic', 'white');
$icon-color: color('gray', 'darken-1');
$icon-background: rgba(color('basic', 'white'), .3);
$link-color: $color-brand--secondary;
$link-color-hover: color('basic', 'white');
$sideBar-shadow: 7px 4px 22px -10px rgba(0,0,0,0.75);
$linkState: $color-brand--primary;
$dividerColor: color('gray', 'text');
$dividerHover: color('basic', 'white');
$svgFilter-hover: brightness(0) invert(1);
.sideMenu {
  &-wrapper {
    background: $sideBar-background;
    margin-top: 60px;
    padding: 5px;
    overflow-y: auto;
    height: calc(100vh - 60px);
    box-shadow: $sideBar-shadow;
  }
  &-link-wrapper {
    .sideBar-tooltip {
      display: none;
    }
    a {
      position: relative;
      @include row-center--start;
      height: auto;
      padding: 10px;
      margin-bottom: 8px;
      color: $link-color;
      transition: $transition-medium;
      &:hover {
        text-decoration: none;
        background: $linkState;
        border-radius: $borderRadius-large;
        color: $link-color-hover;

        .sideMenu-icon {
          filter: $svgFilter-hover;
        }
      }
      .sideMenu-icon {
        color: $iconColor;
        height: 22px;
      }
    }

    a.active {
      background: $linkState;
      border-radius: $borderRadius-large;
      color: $link-color-hover;

      .sideMenu-icon {
        filter: $svgFilter-hover;
      }
    }
    &:hover .sideBar-tooltip {
      display: none;
    }
  }
  &-icon {
    font-size: $fontSize-mega + 2;
    color: $icon-color;
    background-position: right bottom;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background: $dividerColor;
  margin: 15px 0;
  border-radius: 0;

}


