//variables
$borderSimple: $border-thin solid transparent;
.language-picker {
  max-width: 80px;
  display: flex;

  .ember-power-select-trigger {
    width: 50px;
    font-size: $fontSize-normal;
    border-bottom: $borderSimple;
  }
}
