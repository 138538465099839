//variables
$header-color: $font-colorReverse;

$content-background: color('basic', 'white');
$content-color: $font-color;

$footer-background: linear-gradient(transparent, color('basic', 'white'));

.regular-box {
  &-wrapper {
    @include column-start--flexStart;
    position: relative;
    margin: 50px 0;
  }
  &-header {
    @include row-center--spaceBetween;
    position: absolute;
    top: -30px;
    left: 3%;
    width: 94%;
    height: 60px;
    background: $diagonal-background--primary;
    border-radius: $borderRadius-large;
    padding: 0 15px;
    text-transform: uppercase;
    box-shadow: 0 5px 20px 0 rgba(color('basic', 'black'), 0.2), 0 13px 24px -11px rgba($color-brand--primary, 0.6);
    z-index: $zIndex-1;
  }
  &-headerTitle {
    color: $header-color;
    @include row-center--start;
    i {
      font-size: 24px;
    }
  }
  &-contentTitle {
    color: $content-color;
  }
  &-content {
    position: relative;
    height: auto;
    background: $content-background;
    color: $content-color;
    width: 100%;
    border-radius: $borderRadius-largest;
    padding: 50px 20px;
    box-shadow: 0 2px 2px 0 rgba(color('basic', 'black'), 0.14), 0 3px 1px -2px rgba(color('basic', 'black'), 0.2), 0 1px 5px 0 rgba(color('basic', 'black'), 0.12);
    @media(min-height: $largeDesktop) {
      height: 500px;
    }
  }
}
