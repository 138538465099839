$separatorBorder: $border-thin solid color('gray', 'separator');
$currentMonth: $color-brand--tertiary;
$otherMonth: color('gray', 'text');
$selectedDay-background: $color-brand--primary;
$selectedDay-color: color('basic', 'white');
$shadowDay: $shadow-box;
$filterBorder: $border-thin solid color('gray', 'text');
$filterBorder-focus: $border-thin solid $color-brand--primary;
$iconFocused: $color-brand--primary;
.datepicker-dropdown {
  width: 280px;
  padding: 10px;
  box-shadow: $shadowDay;
  margin-top: 4px;
  @include media-breakpoint-up(md) {
    width: 335px;
  }
}

.ember-power-calendar {
  &-row {
    margin-bottom: 10px;
  }
  &-nav-title {
    font-size: $fontSize-mega;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-weekdays {
    font-size: $fontSize-normal;
    text-transform: uppercase;
    font-weight: 500;
    padding-bottom: 5px;
    border-bottom: $separatorBorder;
  }
  &-day {
    color: $currentMonth;
    margin-left: 10px;
    height: 35px;

    &:hover {
      cursor: pointer;
    }
    &--other-month {
      color: $otherMonth;
    }
    &--selected {
      height: 28px;
      background-color: $selectedDay-background;
      border-radius: 50%;
      font-weight: 400;
      color: $selectedDay-color;
      @include media-breakpoint-up(md) {
        height: 35px;
      }
      &:hover {
        font-weight: normal !important;
        box-shadow: $shadowDay;
      }
    }
  }
  &-nav {
    margin-bottom: 10px;
  }
  &-nav-control {
    transition: $transition-fast;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    &--next {
     margin-right: 2px;
    }
    &--prev {
      margin-left: 2px;
    }
    &:hover {
     cursor: pointer;
      background-color: $selectedDay-background;
      color: $selectedDay-color;
    }
    &:focus {
      outline: none;
    }
  }
}

.filter-select {
  .filter-period-wrapper{
    font-size: $fontSize-normal;
    &:focus,
    &:active {
      outline: none;
    }
    &.dropdown-open {
      border-bottom: $filterBorder-focus;
      .material-icons {
        color: $iconFocused;
      }
    }
    .clear-btn {
      font-size: $fontSize-mili;
    }
  }
}


