//variables
$initiativeText-color: $color-brand--secondary;
$initiativeContent-background: color('basic', 'white');
$initiativeAvg-text: color('basic', 'orange');
$dateIcons: color('gray', 'text');
$dateText: $color-brand--secondary;
$metaText: $color-brand--tertiary;
$metaIcons: color('gray', 'text');
.initiative-overview {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: $initiativeText-color;
  min-height:325px;

  .btn--simpleReverse {
    span {
      text-transform: uppercase;
    }
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  &-info {
    display: flex;
    margin-bottom: 10px;
  }

  &-image {

    width: 30%;

    @include media-breakpoint-down(md) {
      width: 100%;
      min-height: 325px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      min-height: 325px;
    }
  }

  &-content {
    width: 70%;
    padding: 20px;
    background: $initiativeContent-background;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  &-average {
    margin-right: 20px;
    color: $initiativeAvg-text;
    display: flex;
    align-items: flex-end;

    .material-icons {
      margin-right: 5px;
      width: 20px;
      font-size: 20px;
    }

    .average-rate-number {
      font-size: $fontSize-normal;
      margin-right: 5px;
    }
  }

  &-date {

    display: flex;
    align-items: flex-end;


    .material-icons {
      font-size: 17px;
      color: $dateIcons;
      margin-right: 5px;
    }
    .date-text {
      font-size: $fontSize-mili;
      color: $dateText;
      line-height: $fontSize-normal;
    }
  }

  h2 {
    font-size: $fontSize-giga;
    font-weight: normal;
    margin-bottom: 10px;
    color: $initiativeText-color;
    @include media-breakpoint-up(md) {
      font-size: $fontSize-tera;
    }
  }

  &-meta {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;

    > div:not(:last-child) {
      margin-right: 10px;
    }
  }

  &-meta-single {
    display: flex;
    align-items: flex-start;
    font-size: $fontSize-normal;
    color: $metaText;
    margin-bottom: 5px;

    .material-icons {
      font-size: $fontSize-normal;
      margin-right: 3px;
      color: $metaIcons;
    }

    > span {
      margin-right: 5px;
      position: relative;
      &:after {
        content: ',';
        position: absolute;
      }

      &:last-of-type:after {
        content: '';
      }
    }

    cursor: auto;
    pointer-events: none;
  }

  &-meta-single.meta-action {
    cursor: pointer;
    pointer-events: auto;
  }

  &-status {
    display: flex;
    margin-bottom: 30px;
    font-size: $fontSize-normal;
    align-items: center;
  }

  .btn--simpleReverse {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    color: $metaText;
    font-size: $fontSize-mili;
    padding: 0;
    transition: $transition-slow;

    .material-icons {
      color: $metaIcons;
      font-size: $fontSize-kilo;
    }
  }

  &-text {
    font-size: $fontSize-normal;
    margin-bottom: 30px;
    color: $initiativeText-color;
    text-align: justify;
  }

  &-footer {
    display: flex;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  &-likes {
    display: flex;
    @include media-breakpoint-down(md) {
      margin-top: 20px;
      width: 100%;
    }
  }

  &-btn-group {
    @include row-center--start;
    flex-wrap: wrap;
    position: relative;

    > div {
      flex-shrink: 0;
    }

    button {
      margin-right: 20px;
    }
    .btn {
      margin-bottom: 0;
      @include media-breakpoint-down(md) {
        margin-bottom: 10px;
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
      }
    }
  }

  .voting-finished {
    position: absolute;
    top: 28px;
    right: 11px;
    color: color('basic', 'red');
    font-size: $fontSize-normal;
    padding: 4px;
    border: 1px solid color('basic', 'red');
    transform: rotate(15deg);
  }



}
