$switcher-background-on : color('brand', 'primary');
$switcher-background-off : $color-brand--tertiary;
$toggle-background: color('basic', 'white');
$toggle-border: $border-thin solid $color-brand--tertiary;
$toggle-borderActive: $border-thin solid $color-brand--primary;
.switcher {
  position: relative;
  @include row-center--start;
  width: 130px;
  height: 30px;
  &-checkbox {
    appearance:none;
    -webkit-appearance:none;
    width: 91px;
    height: 20px;
    background-color: $switcher-background-off;
    position: relative;
    border-radius: 20px;
    transition: $transition-slow;
    &:hover {
      cursor: pointer;
    }
    &:after {
      content: '';
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: $toggle-background;
      border: $toggle-border;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      transition: $transition-slow;
    }
    &:checked {
      &.switcher-checkbox {
        background: $switcher-background-on;
        &:after {
          border: $toggle-borderActive;
          left: 48px;
        }
      }
    }
    &:focus {
      outline: none;
      background-color: $switcher-background-off !important;
    }

    &:checked {
      &:focus {
        background-color: $switcher-background-on !important;
      }
    }
  }
  label {
    margin-left: 10px;
  }
}