//variables
$filterColor: $color-brand--tertiary;
$inputColor: $color-brand--secondary;
$dateFilter-border: $border-thin solid $color-brand--tertiary;
.filter {

  &-bar {
    @include row-start--center;
    align-items: flex-start;
    color: $filterColor;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
      justify-content: flex-start;

    }
    @include media-breakpoint-up(xl) {
      flex-wrap: nowrap;
    }
  }

  &-select {
    margin-right: 15px;

    .filter-period-wrapper {
      border-bottom: $dateFilter-border;
    }
  }

  &-period {
    position: relative;
    padding: 5px;
    min-width: 215px;
    width: 100%;
    height: 30px;
    cursor: pointer;
    color: $inputColor;

    .material-icons {
      position: absolute;
      right: 7px;
      font-size: 18px;
      top: 4px;
    }
  }

  &-period-wrapper {
    position: relative;

    .clear-btn {
      position: absolute;
      right: 10px;
      cursor: pointer;
      top: -15px;
      color: color('basic', 'red');
    }
  }
}
