$messageArea-width: 300px;
$messageArea-widthResponsive: 130px;
$messageArea-imgWidth: 60px;
$chatArea-imgWidth: 80px;
$chatArea-height: 200px;
$chatHeader-height: 205px;

.messages {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  border: 1px solid color('gray', 'text');

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  &-area {
    width: 100%;
    display: flex;
    background: color('basic', 'white');
    padding: 0 10px;
    flex-direction: column;
    min-height: 100px;

    @include media-breakpoint-up(md) {
      height: calc(100vh - 100px);
      width: 130px;
      border-right: 1px solid $color-brand--tertiary;
    }

    @include media-breakpoint-up(lg) {
      width: $messageArea-width;
    }

    &-header {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 10px;
      justify-content: space-between;
      border-bottom: 1px solid color('gray', 'text');
      color: color('brand', 'secondary');
      background: color('basic', 'white');

      .material-icons {
        color: color('brand', 'primary');
        font-size: $fontSize-mega;
      }

      a {
        &:focus {
          outline: none;
        }
      }
    }

    &-content {
      display: flex;
      width: 100%;
      overflow-y: auto;
      flex-direction: row;

      @include media-breakpoint-up(md) {
        flex-direction: column;

      }
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }
    }

    &-single {
      padding: 10px;
      display: flex;
      border-bottom: $border-thin solid color('gray', 'background');

      @include media-breakpoint-up(md) {
        padding: 10px 10px 10px 0;
      }

      &:hover {
        text-decoration: none;
      }

      .image-changer {
        width: $messageArea-imgWidth;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 48px;
          height: 48px;
        }
      }

      &-content {
        width: calc(100% - #{$messageArea-imgWidth});
        display: none;
        flex-direction: column;
        position: relative;

        @include media-breakpoint-up(lg) {
          display: flex;
        }
      }

      &-name {
        color: color('brand', 'secondary');
        font-size: $fontSize-normal;
        font-weight: 500;
        margin-bottom: 5px;
      }

      &-message {
        color: color('brand', 'tertiary');
        font-size: $fontSize-mili;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      &-date {
        margin-top: 5px;
        text-align: right;
        width: 100%;
        color: color('brand', 'tertiary');
        font-size: $fontSize-mili;
      }
    }

    &-single.active {
      position: relative;

      img {
        border: 2px solid $color-brand--primary;
      }
    }
  }

  &-chat {
    height: calc(100vh - #{$chatHeader-height});
    position: relative;
    background: color('basic', 'white');
    width: 100%;
    @include media-breakpoint-up(md) {
      height: calc(100vh - 100px);
    }
    @include media-breakpoint-up(lg){
      width: calc(100% - #{$messageArea-width});
    }
  }

  &-chat-container {
    overflow-y: auto;
    //padding-bottom: 130px;
    overflow-x: hidden;
    max-height: calc(100vh  - #{$chatArea-height});
    padding: 20px 0 100px 0;
    @include media-breakpoint-up(lg){
      padding: 10px 0 0 0;
    }

    &-header {
      position: absolute;
      top: 0;
      z-index: $zIndex-2;
      background: color('basic', 'white');
      width: 100%;
      color: color('brand', 'secondary');
      font-size: $fontSize-kilo;
      font-weight: 500;
      border-bottom: 1px solid color('gray', 'text');
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 7px 12px;
      label {
        margin-right: 10px;
      }
      @include media-breakpoint-up(md) {
        padding: 12px;
      }
    }
  }

  &-chat-message {
    padding: 10px;
    display: flex;
    margin: 45px 0 10px 0;
    justify-content: flex-start;
    flex-direction: row;

    .image-changer {
      width: $chatArea-imgWidth;
      display: flex;
      justify-content: center;

      img {
        width: 50px;
        height: 50px;
      }
    }

    &-content {
      background: color('gray', 'background');
      color: color('brand', 'secondary');
      padding: 10px;
      border-radius: $borderRadius-large;
      font-size: $fontSize-normal;
      position: relative;
      width: calc(100% - #{$chatArea-imgWidth});

      &:after {
        content: '';
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid color('gray', 'background');
        position: absolute;
        right: -5px;
        top: 15px;
      }
    }

    &-date {
      position: absolute;
      bottom: -30px;
      left: 5px;
      font-size: $fontSize-mili;
      text-align: right;
      color: color('gray', 'text');

      @include media-breakpoint-up(md) {
        bottom: -20px;
        text-align: right;
      }
    }
  }

  //reverse chat message
  &-chat-message.other-user {
    flex-direction: row-reverse;

    .messages-chat-message-content {
      background: color('brand', 'primary');
      color: color('basic', 'white');

      &:after {
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 5px solid color('brand', 'primary');
        border-left: none;
        position: absolute;
        left: -5px;
        right: inherit;
      }
    }

    .messages-chat-message-date {
      left: inherit;
      right: 5px;
    }
  }

  &-chat-input {
    position: absolute;
    bottom: 0;
    padding: 5px 10px;
    background: white;
    width: 100%;
    border-top: 1px solid color('gray', 'text');
    .btn--small {
      padding: 10px;
    }
    @include media-breakpoint-up(md) {
      bottom: 20px;
      .btn--small {
        padding: 10px 20px;
      }
    }
    .btn {
      span {
        display: none;

        @include media-breakpoint-up(md) {
          display: block;

        }
      }

      .material-icons {
        margin-right: 0;

        @include media-breakpoint-up(md) {
          margin-right: 10px;
        }
      }
    }

    form {
      display: flex;
      align-items: center;
    }

    textarea {
      border: none;
      width: 100%;
      padding: 10px 20px 10px 10px;
      font-size: $fontSize-normal;
      line-height: $fontSize-normal;

      &:focus {
        outline: none;
      }
    }
  }

  &-general {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h3 {
      z-index: $zIndex-1;
      font-size: $fontSize-giga;
      text-align: center;
      font-weight: 500;

      @include media-breakpoint-up(lg) {
        font-size: $fontSize-tera;
      }
    }
    &:before {
      content: '\e0c9';
      font-family: 'Material Icons';
      position: absolute;
      color: color('gray', 'background');
      font-size: 150px;
      @include media-breakpoint-up(lg) {
        font-size: 400px;
      }
    }
  }
}

.tooltip-change-messages {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }
}
