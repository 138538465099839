$switherBackground: color('gray', 'text');
$switherBackground-active: $color-brand--primary;
$mediaLibrary-background: color('basic', 'white');
$mediaBtn-color: $color-brand--primary;
.layout-changer {
  width: 100%;
  display: flex;
  padding: 10px;
  position: relative;
  background: $mediaLibrary-background;
  &-btn {
    padding: 5px;
    color: $mediaBtn-color;
    cursor: pointer;
    position: relative;
    min-width: 25px;
    min-height: 27px;
    &:first-of-type {
      border-right: none;
    }
    .grid-icon {
      transition: $transition-fast;
      position: absolute;
      background-color: $switherBackground;
      z-index: 5;
      width: 7px;
      height: 7px;
      &:first-child {
        top: 5px;
        left: 5px;
      }
      &:nth-child(2) {
        top: 5px;
        left: 15px;
      }
      &:nth-child(3) {
        bottom: 5px;
        left: 5px;
      }
      &:last-child {
      }
      bottom: 5px;
      left: 15px;
    }

    .row-icon {
      margin-left: 10px;
      transition: $transition-fast;
      position: absolute;
      height: 3px;
      width: 16px;
      background-color: $switherBackground;

      &:nth-child(2) {
        top: 12px;
        width: 13px;
      }
      &:last-child {
        top: 19px;
      }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: -5px;
        width: 3px;
        height: 3px;
        background-color: $switherBackground;
        z-index: 5;
      }
    }
  }

  &-btn--active {
    .grid-icon {
      background-color: $switherBackground-active;
    }
    .row-icon {
      background-color: $switherBackground-active;
      &:after {
        background-color: $switherBackground-active;
      }
    }
  }
  .mobileRemove {
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
    }
  }
}
