//variables
$noteText: color('basic', 'red');
.image-changer {
  position: relative;

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
  }

  .btn--circle {
    position: absolute;
    left: 0;
    top: 10px;
    padding: 0;

    .material-icons {
      font-size: 20px;
      margin: 0;
    }
  }

  &-tmp-wrapper {
    margin-top: 15px;
  }

  &-note {
    width: 100%;
    color: $noteText;
    padding: 10px 0 5px 0;
    font-size: 12px;
    text-align: center;
  }

  &--extra-large {
    img {
      width: 128px;
      height: 128px;
    }
  }
  &--large {
    img {
      width: 96px;
      height: 96px;
    }
  }
  &--medium {
    img {
      width: 64px;
      height: 64px;
    }
  }
  &--small {
    img {
      width: 40px;
      height: 40px;
    }
  }
  &--shadow {
    img {
      box-shadow: $shadow-box;
    }
  }
}

//custom styles for different cases
.initiative-form {

  .image-changer {
    margin: 25px 0 15px;
    .btn--circle {
      left: 10px;
      top: -15px;
    }
    img {
      border-radius: 0;
      width: 100%;
      height: 100%;
      max-height: 300px;
      object-fit: cover;
      @media only screen
      and (min-device-width: 320px)
      and (max-device-width: 640px)
      and (orientation: landscape) {
        height: auto;
      }
    }
  }

  .image-changer-tmp-wrapper {
    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
  }

}
