$likerText: $color-brand--secondary;
$materialIcon-regular: $color-brand--tertiary;
$materialIcon-liked: $color-brand--primary;
.liker {
  color: $likerText;
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: $fontSize-normal;
  cursor: pointer;

  .material-icons {
    color: $materialIcon-regular;
    font-size: 15px;
    margin-right: 3px;
  }

  .material-icons.liked {
    color: $materialIcon-liked;
  }

  .material-icons.disabled-actions {
    cursor: not-allowed;
    position: relative;
  }

  button {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    margin: 0 10px;
    &:disabled {
      cursor: default;
    }
  }
}
