//variables
$headerWrapper-background: color('basic', 'white');
$activeIcon: $color-brand--primary;
$containerOverflow: calc(100% + 70px);
$containerOverflowLarge: calc(100% + 50px);

.filter-wrapper {
  background-color: $headerWrapper-background;
  padding: 0;
  width: 100%;
  align-items: center;
  border-radius: $borderRadius-medium;
  flex-direction: row;
  margin-bottom: 50px;
  position: relative;
  z-index: 2;
  margin-top: 15px;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: $containerOverflow;
    height: 100%;
    transform: translateX(-50%);
    background-color: $headerWrapper-background;
    z-index: -1;
  }
  @include media-breakpoint-up(md) {
    padding: 15px;
    width: auto;
    &:after {
      width: $containerOverflowLarge;
    }
  }

  @media screen  and (min-width: $custom-xl-breakpoint) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
  }
  .layout-changer {
    width: auto;
  }
  .filter-bar {
    justify-content: flex-start;
    width: 100%;
    padding: 10px;
    flex-direction: column;
    @include media-breakpoint-up(sm) {
      flex-direction: row;

    }
    @media screen  and (min-width: $custom-xl-breakpoint) {
      justify-content: center;
      flex-direction: row;
      width: 70%;
      padding: 0;
    }
    &.filter-only {
      width: 100%;
      justify-content: space-between;
    }
    .filter-select {
      margin-bottom: 20px;
      width: 100%;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }
  .search-bar {
    border: none;
    width: auto;
    height: 30px;
    @media screen and (min-width: $custom-xl-breakpoint) {
      margin-left: 25px;
    }
    .input-underline {
      width: 0;
      height: 1px;
      position: absolute;
      bottom: -8px;
      transform: scale(0);
      transition: $transition-fast;
    }
    .btn {
      .material-icons {
        transition: $transition-fast;
      }
    }
    .search-bar-input {
      width: 100%;
      &:focus {
        &~.input-underline {
          width: 100%;
          background-color: $color-brand--primary;
          transform: scale(1);
        }
        &~.btn {
          .material-icons {
            color: $activeIcon;
          }
        }
      }
    }
  }
}

