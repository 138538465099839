$borderWrapper: $border-thin solid transparent;
$mediaItem-background: color('basic', 'white');
$mediaPlaceholder-color: color('gray', 'separator');
$mediaItemOdd-background: color('gray', 'background');
$mediaHeader: $color-brand--primary;
$mediaItem-border: $border-thin solid color('gray', 'background');
$headerColor: color('basic', 'white');
$iconsDefault: $color-brand--tertiary;
$editIcon-hover: $color-brand--primary;
$deleteIcon-hover: color('basic', 'red');
.media-library {
  width: auto;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;

  @include media-breakpoint-between(sm, md) {
    justify-content: center;
  }

  @include media-breakpoint-up(lg) {
    width: 100%;
    justify-content: flex-start;
  }

  .media-text {
    font-size: $fontSize-mega;
    @include media-breakpoint-up(md){
      font-size: $fontSize-giga;
    }

  }

  &-wrapper {
    width: 100%;
    display: flex;
    border: $borderWrapper;
    flex-direction: column;
    margin-top: 20px;
    flex-wrap: wrap;
  }
  &-row {
    margin: 0;
    overflow: auto;
  }
  &-header {
    @include row-center--spaceBetween;
    color: $headerColor;
    &-cell {
      width: auto;
      min-width: 150px;
      text-transform: uppercase;
      font-size: $fontSize-mili;
      font-weight: 500;
      @include media-breakpoint-up(sm) {
        width: 20%;
      }
    }
  }
  .dialog-header {
    padding: 10px 15px;
    .btn {
      padding: 0;
      margin: 0;
    }
  }

  &-cell {
    width: auto;
    min-width: 150px;
    font-size: $fontSize-normal;
    @include media-breakpoint-up(sm) {
      width: 20%;
    }
  }

  &-cell:not(.material-icons) {
    @include text-truncate;
  }

  &-icon {
    font-size: 35px !important;
    color: $mediaPlaceholder-color;
    @include media-breakpoint-up(sm) {
      font-size: 55px !important;
    }
  }

  &-item {
    height: auto;
    margin-right: 10px;
    @include row-center--spaceBetween;
    background-color: $mediaItem-background;
    padding: 10px;
    &:nth-child(odd) {
      background-color: $mediaItemOdd-background;
    }

    img {
      width: 70px;
      height: 70px;
      object-fit: cover;
    }
    &.media-library-item--row {
      flex-direction: column;
      align-items: center;
      text-align: center;
      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
      .media-library-name {
        margin: 20px 0;
        @include media-breakpoint-up(md) {
          text-align: center;
          margin: 0;
        }
      }
    }
    &.media-library-item--grid {
      flex-direction: column;
      width: 100%;
      padding: 15px 10px;
      @include media-breakpoint-up(md){
        width: 48%;
      }
      @include media-breakpoint-up(xl) {
        width: 24%;
      }
      .media-library-name {
        text-align: center;
        margin: 20px 0;
      }
    }
  }
  .layout-changer {
    margin-bottom: 10px;
  }
  .btn {
    @include row-center--center;
    margin-right: 5px;
    width: auto;

    .material-icons {
      margin-right: 10px;
      font-size: $fontSize-mili;
      transition: $transition-fast;
      color: $headerColor;
    }
    &-wrapper {
      @include column-start--flexStart;
      width: 20%;
      .material-icons {
        font-size: $fontSize-mega + 2;
      }
      @include media-breakpoint-up(sm){
      flex-direction: row;
      }
    }
    &:first-child {
      .material-icons:not(.cloud-upload) {
        color: $iconsDefault;
        &:hover {
          color: $editIcon-hover;
        }
      }
    }
    &:last-child {
      .material-icons:not(.cloud-upload) {
        color: $iconsDefault;
        &:hover {
          color: $deleteIcon-hover;
        }
      }
    }
  }
  &-header {
    width: 100%;
    height: 55px;
    padding: 10px;
    background-color: $mediaHeader;
  }
  .no-media-wrapper {
    min-height: 50px;
    @include row-center--center;
  }
}

