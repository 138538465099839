$activeColor: $color-brand--primary;
$inactiveColor: lighten($color-brand--primary, 30%);
$progressBar-background: color('basic', 'white');
.progress-status {
  &-wrapper {
    @include column-start--flexStart;
    .progress-bar-title {
      font-weight: 500;
      font-size: $fontSize-normal;
      padding: 10px 0;
    }
  }
  &-all {
    @include row-center--start;
    flex-wrap: wrap;
  }
  &-single {
    width: 100%;
    max-width: 50px;
    height: 7px;
    background: $inactiveColor;
    margin-right: 5px;

    &:last-of-type {
      margin-right: 0;
    }
  }
  &-tooltip {
    display: flex;
    flex-direction: column;

    .name {
      font-size: $fontSize-normal;
      margin-bottom: 5px;
    }

    .date {
      font-size: $fontSize-mili;
    }
  }

}
