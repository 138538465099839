//variables
$login-boxShadow: $shadow-box;
$loginBackground: color('basic', 'white');
$resetPassword-color: $color-brand--primary;
$resetPassword-states: darken($resetPassword-color, 20%);
.login {
  &-wrapper {
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: url('/assets/images/login.jpg') no-repeat;
    background-size: cover;
    background-position: center bottom;

    .validation-field {
      margin: 0 15px;

    }
  }


  &-form {
    .material-icons {
      bottom: 5px;
      font-size: $fontSize-kilo;
    }
    width: 290px;
    background: color('basic', 'white');
    padding: 35px 20px;
    border-radius: 6px;
    box-shadow: $shadow-light;
    @include media-breakpoint-up(sm) {
      width: 350px;
    }

    form {
      @include row-center--center;
      flex-wrap: wrap;

      button {
        margin-top:10px;
      }
    }

    .validation-field {
      width: 100%;

      .material-icons {
        bottom: 5px;
        font-size: $fontSize-kilo;
      }
    }

    .btn {
      text-transform: uppercase;
    }
  }

  &-header {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;

    &.logo-wrapper {
      @include row-center--center;
      img {
        &:first-child {
          width: auto;
          height: 40px;
        }
        &:nth-child(2) {
          height: 28px;
          margin-left: 10px;
        }
      }
    }
  }

  &-actions {
    @include row-end--spaceAround;
    font-size: $fontSize-normal;
    width: 100%;
    margin-top: 20px;
    .reset-pass {
      font-size: $fontSize-mili;
      font-style: italic;
      text-transform: capitalize;
      color: $resetPassword-color;
      &:visited {
        color: $color-brand--primary;
      }
    }
  }

}
.back-to-login {
  @include row-center--center;
  color: $resetPassword-color;
  transition: $transition-fast;
  font-size: $fontSize-mili;
  margin-left: 10px;


  &:hover {
    color: $resetPassword-states;
  }
}

.reset-password-actions,
.recover-password-wrapper{
  @include row-center--spaceBetween;

  .btn {
    margin-right: 10px;
  }
  a {
    font-size:14px;
    margin-top:10px;
    i {
      &:focus {
        outline: none;
      }
    }
  }

}
