/* topbar variables */
$topBar-background: color('basic', 'white');
$topBar-color: color('brand', 'primary');
$topBar-shadow: 0 3px 8px 0 rgba(40, 42, 47, .2);
$topBar-notificationIndicator: $color-brand--primary;
$topBar-notificationBorder: $border-normal solid color('basic', 'white');
$topBar-link: $color-brand--tertiary;
$topBar-icon: $color-brand--secondary;
$topBar-name: $color-brand--secondary;
$openerIcon: $color-brand--secondary;
$iconDefault: $color-brand--primary;
$dropdownColor: $color-brand--secondary;
$dropdownBackground-hover: color('gray', 'background');
$messageBorder: $border-thin solid color('gray', 'darken-1');
$messageShadow: $shadow-box;
$messageFooterBackground: color('gray', 'background');
$messageLink: $color-brand--primary;
$singleMessage-border: $border-thin solid color('gray', 'separator');
$onlineStatus-active: $color-brand--primary;
$onlineStatus-inactive: color('gray', 'background');
$messagesBackground: color('basic', 'white');
$fakeShadow: color('gray', 'lighten-3');
.top-bar {
  @include row-center--flexEnd;
  background: $topBar-background;
  color: $topBar-color;
  height: 60px;
  padding: 0 10px;
  box-shadow: $topBar-shadow;
  z-index: $zIndex-4;
  position: fixed;
  width: calc(100% - #{$sideBar-width});
  margin-left: $sideBar-width;

  .ember-power-select-trigger {
    width: 48px;
    @include media-breakpoint-up(md) {
      width: 200px;
    }
    .language-picker {
      .ember-power-select-status-icon {
        height: 0;
      }
    }
  }

  &-link {
    display: flex;
    align-items: center;
    margin-right: 5px;
    color: $topBar-link;
    font-size: $fontSize-normal;
    cursor: pointer;
    position: relative;
    @include media-breakpoint-up(md) {
      margin-right: 25px;
    }

    &:last-child {
      margin-right: 0;
    }

    .notification-circle {
      position: absolute;
      content: '';
      top: -12px;
      left: -8px;
      width: 23px;
      height: 23px;
      background-color: $topBar-notificationIndicator;
      border-radius: 50%;
      border: $topBar-notificationBorder;
      color: color('basic','white');
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
    }

    .material-icons {
      font-size: 20px;
      margin-right: 5px;
      color: $topBar-icon;
    }
  }

  &-user {

    &-panel {
      display: flex;
      flex-direction: row;
      margin-left: 5px;
      cursor: pointer;
      align-items: center;
      position: relative;
      padding-right: 20px;
      @include media-breakpoint-up(md) {
        margin-left: 10px;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      margin: 0 10px;
    }

    &-message {
      font-size: $fontSize-mili;
      color: $topBar-link;
      margin-bottom: 3px;
    }

    &-name {
      font-size: $fontSize-normal;
      color: $topBar-name;
    }
  }

  &-drop-down{
    &-wrapper {
      min-width: 150px;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 65px !important;
      right: 15px !important;
      border-radius: $borderRadius-small;
      background: $messagesBackground;
      box-shadow: $shadow-box;
    }
    &-messages {
      &.ember-basic-dropdown-content--left {
        box-shadow: $shadow-box;
        top: 55px!important;
        background: $messagesBackground;
        &:before {
          top: -5px;
          left: 42px;
          position: absolute;
          content: '';
          width: 10px;
          height: 10px;
          transform: rotate(45deg);
          z-index: -1;
          background-color: $messagesBackground;
        }
        &:after {
          position: absolute;
          top: -6px;
          left: 42px;
          content: '';
          width: 10px;
          height: 10px;
          transform: rotate(45deg);
          background: $fakeShadow;
          z-index: -2;
        }
      }
      &.ember-basic-dropdown-content--right {
        box-shadow: $shadow-box;
        top: 55px !important;
        background: $messagesBackground;
        &:before {
          top: -5px;
          right: 42px;
          position: absolute;
          content: '';
          width: 10px;
          height: 10px;
          transform: rotate(45deg);
          z-index: -1;
          background-color: $messagesBackground;
        }
        &:after {
          position: absolute;
          top: -6px;
          right: 42px;
          content: '';
          width: 10px;
          height: 10px;
          transform: rotate(45deg);
          background: $fakeShadow;
          z-index: -2;
        }
      }
    }
    &-content-wrapper {
      max-height: calc(5*61px + 38px);
      width: 220px;
      overflow-y: auto;
      padding-bottom: 37px;
      background: white;
      @include media-breakpoint-up(md) {
        width: 350px;
      }
    }
    &-content {
      padding: 10px;
      border-bottom: $singleMessage-border;
      color: $dropdownColor;
      @include row-center--spaceBetween;
      position: relative;
      transition: $transition-fast;
      .top-bar-message-info {
        width: 100%;
        margin-left: 10px;
        margin-top: 10px;
        @include media-breakpoint-up(md) {
          margin-top: 0;
        }
      }
      .message-author {
        font-weight: 500;
        font-size: $fontSize-normal;
      }
      .message-text {
        max-width: 130px;
        margin-top: 5px;
        @include text-truncate;
        font-size: 13px;
        position: relative;
        @include media-breakpoint-up(md) {
          max-width: 220px;
        }
      }

      .message-date {
        position: absolute;
        top: 5px;
        right: 10px;
        color: color('brand', 'tertiary');
        font-size: $fontSize-mili;
        @include media-breakpoint-up(md) {
          top: 10px;
        }
      }

      .message-state {
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: $onlineStatus-inactive;
        bottom: 10px;
        right: 10px;
      }

      .message-state.not-read {
          background-color: $onlineStatus-active;
      }

      &:hover {
        cursor: pointer;
        background-color: $dropdownBackground-hover;
      }
    }
    &-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: center;
      border-top: $messageBorder;
      width: 100%;
      padding: 11px;
      background-color: $messageFooterBackground;

      .messages-footer-link {
        text-decoration: none;
        color: $messageLink;
      }

      &.notification-footer {
        height: 39px;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: $fontSize-normal;
      padding: 13px 10px;
      color: $dropdownColor;
      &:hover {
        background: $dropdownBackground-hover;
      }

      &:first-child {
        border-radius: $borderRadius-small $borderRadius-small 0 0;
      }

      &:last-child {
        border-radius: 0 0 $borderRadius-small $borderRadius-small;
      }

      .material-icons {
        font-size: $fontSize-kilo;
        margin-right: 5px;
        color: $iconDefault;
      }
    }

    &-opener {
      position: absolute;
      right: 0;
      font-size: $fontSize-mega;
      color: $openerIcon;
    }
  }
  &-message-label,
  &-notification-label {
    display: none;
    font-size: $fontSize-mili;
    @include media-breakpoint-up(md) {
      display: block;
    }
  }
  &-user-name {
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
    }
  }
  &-user-name.mobile {
    display: block;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &--narrow {
    width: calc(100% - #{$sideBar-width--small});
    margin-left: $sideBar-width--small;
  }
}


